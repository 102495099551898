mat-list-item {
  border-bottom: 1px solid #f2f2f2;
  &:nth-child(odd) {
    background-color: #fcfcfc;
  }
}

.mat-list-item-content {
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ct-list-item {
  &__text {
    font-size: 14px;
    font-weight: 400;

    &--dimmed {
      color: #aaa;
    }
  }

  &__icon {
    margin-left: 9px;
    color: #737272;
    cursor: pointer;
    transform: scale(0.76) rotate(0.3deg);

    &--dimmed {
      color: #aaa;
    }

    &--loading {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}
