.expertise-tag {
  cursor: pointer;
  border-radius: 3px;
  font-size: var(--font-size-pill);
  height: 1rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  background-color: #9c96c3;
  color: white;
  margin-bottom: 0.1875rem;
  margin-right: 0.1875rem;
}
