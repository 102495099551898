.tab-buttons {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
  border-bottom: 1px solid #dedede;
  .tab-button {
    cursor: pointer;
    font-size: 18px;
    margin-right: var(--spacing-l);
    color: #7f7f7f;
    &.active {
      border-bottom: 3px solid steelblue;
      color: #404040;
    }
  }
}

.expert-profile {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .expert-profile-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    padding: var(--spacing-m);

    .tab-content {
      overflow-y: auto;
    }

    .expert-profile-header {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: var(--spacing-m);
      h1 {
        margin-right: var(--spacing-s);
      }
      .rejected {
        margin-right: var(--spacing-xl);
      }
      .status-box {
        height: 30px;
        padding: 2px;
        border: 1px solid #ccc;
        border-radius: 3px;
        display: flex;
        align-items: center;
        padding: 0 var(--spacing-s);
        mat-icon {
          margin-right: var(--spacing-xs);
          transform: scale(0.8666);
        }
      }
      .close-icon {
        position: absolute;
        right: 0;
      }
    }
    .expert-missing-timezone {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: var(--spacing-l);
      }
      .input-container {
        width: 300px;
        .timezone-selector-input {
          width: 315px;
        }
        .drop-down {
          max-height: 260px;
          overflow-y: scroll;
        }
      }
    }
    .expert-availability {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--spacing-l);
      h2 {
        margin-bottom: var(--spacing-s);
      }
      .update-call-time-icon {
        transform: scale(0.7);
        position: relative;
        top: 5px;
        left: 3px;
        cursor: pointer;
      }
      .update-call-time-input-container {
        input {
          margin-right: var(--spacing-s);
        }
      }
      .clear-update-call-time-icon {
        transform: scale(0.7);
        position: relative;
        top: 9px;
        left: 3px;
        cursor: pointer;
      }

      .update-call-time-input-container {
        display: flex;
      }
      .pagination-label {
        margin-bottom: var(--spacing-s);
      }
    }
    .recording-box {
      display: flex;
      align-items: center;
      height: 60px;
      background-color: #f2f2f2;
      padding: 0 var(--spacing-m);
      border: 1px solid #ccc;
      margin-bottom: var(--spacing-l);
      mat-icon {
        transform: scale(1.5);
        margin-right: var(--spacing-m);
        &.disabled-recording-icon {
          color: lightslategray;
        }
        &.enabled-recording-icon {
          color: darkseagreen;
        }
      }
      p {
        margin-right: var(--spacing-m);
      }
      .toggle-label {
        margin-right: var(--spacing-s);
        margin-left: var(--spacing-s);
      }
    }
    .expert-details {
      margin-bottom: 60px;
      h2 {
        margin-bottom: var(--spacing-s);
      }
      .details-flex-row {
        display: flex;
        align-items: center;
        margin-bottom: var(--spacing-m);
        .input-container {
          margin-right: var(--spacing-s);
          &:last-child {
            margin-right: 0;
          }
          .file-list-container {
            display: flex;
            align-items: center;
            .list-input {
              width: 100%;
              margin-right: var(--spacing-s);
            }
          }
        }
        app-timezone-selector {
          .drop-down {
            right: 0;
            height: 24rem;
            overflow-y: scroll;
          }
        }
        .width-50 {
          width: 50%;
        }
        .width-25 {
          width: 25%;
        }
      }
    }
    .expert-biography {
      display: flex;
      flex-direction: column;
      .bio-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--spacing-s);
        .links {
          display: flex;
          align-items: center;
          button,
          app-expert-previous-bio-button {
            margin-right: var(--spacing-s);
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .bio {
        height: 500px;
        margin-bottom: 50px;
      }

      .prev-and-gen-bio {
        display: flex;
        flex-direction: column;
        min-height: 250px;
        .prev-and-gen-bio-actions {
          display: flex;
          align-items: center;
          padding-top: var(--spacing-s);
          button {
            margin-left: var(--spacing-s);
            position: relative;
            top: 10px;
          }
        }
      }
      .bio-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: var(--spacing-s);
        button {
          margin-right: var(--spacing-s);
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .expert-missing-currency {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: var(--spacing-l);
      }
      .input-area {
        display: flex;
        align-items: flex-end;
        .input-container {
          width: 300px;
          margin-right: var(--spacing-m);
          .drop-down {
            max-height: 260px;
            overflow-y: scroll;
            top: 110px;
          }
        }
      }
    }
    .expert-billing {
      .billing-actions,
      .billing-info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        flex-direction: row;
      }
      .billing-info {
        justify-content: flex-end;
        .input-row {
          display: flex;
          .input-container {
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .billing-actions {
        justify-content: flex-start;
        .add-engagement-buttons {
          display: flex;
          align-items: center;
          button {
            border: 1px solid mediumslateblue;
            color: mediumblue;
            margin-right: 1rem;
          }
        }
      }
      .summary {
        margin: 40px 0 80px 0;
        .input-container {
          margin-bottom: var(--spacing-m);
        }
        .input-row {
          display: flex;
          align-items: center;
          margin-bottom: var(--spacing-m);
          .input-container {
            margin-right: var(--spacing-s);
            width: 30%;
            margin-bottom: 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .expert-profile-footer {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-m);
    border-top: 1px solid #f1f1f1;
    justify-content: flex-end;
    justify-content: space-between;
    .expert-profile-footer-label {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        mat-icon {
          transform: scale(0.7);
        }
      }
    }
    .expert-profile-footer-actions {
      display: flex;
      align-items: center;
      button {
        margin-right: var(--spacing-m);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.availibility-button {
  cursor: pointer;
}

.align-row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
