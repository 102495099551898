.base-input-input {
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  font-size: var(--font-size-p);
  outline: none;
  &.danger {
    border: 1px solid indianred;
  }
}
