html {
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  //page header
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  color: var(--font-color);
}

h2 {
  //card header / form header
  text-transform: uppercase;
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  color: var(--font-color);
}

h3 {
  font-weight: var(--font-weight-h3);
  font-size: var(--font-size-h3);
  color: var(--font-color);
}

p {
  font-size: var(--font-size-p);
  font-weight: var(--font-weight-p);
  color: var(--font-color);
  &.danger {
    color: #f44336;
  }
  &.bold {
    font-weight: 600;
  }
}
