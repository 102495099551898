ul {
  padding: 0;
  margin: 0;
}

input,
select,
mat-dialog-container {
  padding: 0 !important;
}
.selectable {
  user-select: all;
}
.action-btn {
  height: 3.5rem;
  min-width: 13rem;
  border: none;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

button:focus {
  outline: 0;
}

.main-layout {
  height: 100vh;
  width: 100%;
}

.main-body {
  margin-left: 6rem;
  height: 100%;
  position: relative;
  z-index: 0;
}

.go-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 30px;
  width: 30px;
  background-color: var(--light-blue);
  color: var(--white);
  border-radius: 3px;
  &:hover {
    cursor: pointer;
    background-color: var(--main-colour);
  }
}

* {
  box-sizing: border-box;
}
