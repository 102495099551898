.ct-opportunity-list-item {
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px !important;
  &__header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__identification {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  &__title {
    height: 23px;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    color: #56595a !important;
  }
  &__ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__pod-list {
    display: flex;
    align-items: center;
  }
  &__actions {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__icon {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  &__content {
    width: 100%;
    overflow-x: auto;
  }
}
