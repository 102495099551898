.scheduler-container {
  display: flex;
  flex-direction: column;
  .scheduler-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 0 var(--spacing-m);
  }
  .scheduler-tabs {
    flex-grow: 1;
  }
}

.schedule-form {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-m);
  box-shadow: 0 2px 5px 0 rgba(77, 95, 128, 0.15);
  border: 1px solid var(--border-gray);
  height: 100%;
  width: 100%;
  background-color: #fff;
  margin: 0;
  h3 {
    margin-bottom: var(--spacing-l);
    font-size: 1.2rem;
  }
  .schedule-form-inputs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 16px;
      font-weight: 600;
      color: #586673;
      margin-bottom: var(--spacing-s);
    }
    .form-input-row {
      display: flex;
      margin-bottom: var(--spacing-l);
      .datetime-span {
        display: flex;
        align-items: center;
        .base-input-input {
          width: 100%;
        }
        mat-icon {
          transform: scale(0.85);
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .input-container {
        width: 30%;
        margin-right: 20px;
        position: relative;
        .label {
          position: relative;
          mat-icon {
            transform: scale(0.56);
            position: absolute;
            color: #303030;
            cursor: help;
            top: -8px;
          }
        }
        .timezone-selector-input-container {
          .drop-down {
            height: 24rem;
            overflow-y: scroll;
          }
        }
        mat-hint {
          position: absolute;
          top: 43px;
        }
      }
    }
  }
  .toggle-section {
    display: flex;
    gap: 10px;

    .recording-toggle-box {
      display: flex;
      align-items: center;
      height: 60px;
      background-color: #f2f2f2;
      padding: 0 var(--spacing-m);
      border: 1px solid #ccc;
      margin-bottom: var(--spacing-l);
      width: 50%;

      mat-icon {
        transform: scale(1.3);
        &.payment-active-icon {
          color: cornflowerblue;
        }
        &.payment-inactive-icon {
          color: lightgray;
        }
      }
      p {
        margin: 0 20px;
      }
      .toggle-container {
        display: flex;
        align-items: center;
        p {
          margin: 0 10px;
        }
      }
    }
  }
  .schedule-form-actions {
    display: flex;
    align-items: center;
    height: 2.3rem;
    justify-content: flex-end;
    button {
      margin-right: var(--spacing-m);
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .schedule-form-list {
    div {
      display: flex;
      align-items: center;
      span:first-child {
        flex: 0 1 120px;
      }
      select {
        width: 260px;
      }
    }
  }
}

.validation-warning {
  color: indianred !important;
  font-size: 0.7555rem;
  margin-top: 4px;
  margin-bottom: 6px;
}

.validation-info {
  color: var(--light-blue) !important;
  font-size: 0.7555rem;
  margin-top: 4px;
  margin-bottom: 6px;
}

.tabs-header {
  display: flex;
  align-items: center;
  button {
    height: 30px;
    min-width: 70px;
    border: 0;
    &.active-btn {
      background-color: #dfe3ed;
    }
  }
  .warning-span {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 12px;
    mat-icon {
      margin-right: 10px;
    }
  }
}
