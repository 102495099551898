.country-zone-input {
  display: flex;
  flex-direction: column;
  .label {
    margin-bottom: 0.2rem;
  }
  .country-zone-input-selects {
    display: flex;
    align-items: center;
    .country-zone-input-select {
      width: 18rem;
      height: 2rem;
      padding: 0 0.5rem;
      background-color: rgba(220, 229, 250, 0.1);
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      font-size: var(--font-size-p);
      outline: none;
    }
    .country-zone-input-chevron {
      transform: scale(1.25);
      margin: 0 2rem;
      color: #bdbdbd;
      display: flex;
      height: 2rem;
      align-items: center;
      justify-content: center;
    }
  }
}
