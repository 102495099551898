.ct-count {
  display: flex;
  align-items: center;
  color: darkgray;
  min-width: 90px;
  &__icon {
    transform: scale(0.85);
  }
  &__value {
    font-size: 0.75rem;
    font-weight: 600;
  }
}
