.ct-generic-chip {
  background-color: #e6e6e6;
  padding: 3px 10px;
  border-radius: 4px;
  display: flex;
  min-height: 24px;
  align-items: center;
  font-size: 14px;
  height: max-content;
  &--small {
    min-height: 19px;
    font-size: 0.75rem;
    margin-bottom: 5px;
  }
  &__text {
    font-weight: 400;
  }
  &__icon {
    margin-left: 9px;
    color: #737272;
    cursor: pointer;
    transform: scale(0.76) rotate(0.3deg);
  }
}
