app-conference-schedule-form {
  app-timezone-selector {
    .input-container {
      margin-bottom: 2rem;
      position: relative;
      .base-input-input {
        width: 35rem;
      }
      .selected-tz-input {
        width: 35rem;
      }
      .drop-down {
        position: absolute;
        top: 54px;
        max-height: 29rem;
        overflow-y: scroll;
      }
      .horizontal-list {
        margin-top: 1rem;
      }
    }
  }
}
