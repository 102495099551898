.email-status-circle {
  width: 13px;
  min-width: 13px;
  height: 13px;
  border-radius: 50%;
  &.fail {
    background-color: var(--email-fail-color);
  }

  &.success {
    background-color: var(--email-success-color);
  }

  &.pending {
    background-color: var(--email-pending-color);
  }

  &.default {
    background-color: var(--email-default-color);
  }
}
