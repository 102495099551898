.tz-list-item {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  .tz-list-item-country {
    font-size: 10px;
    font-weight: 400;
    color: #a5a5a5;
    text-transform: uppercase;
  }
  .tz-list-item-timezone {
    font-weight: 400;
    color: #6c757d;
    font-size: 14px;
  }
}
