.side-dialog {
  padding: 15px;
  display: flex;
  flex-direction: column;
  .top {
    .input-container {
      margin-top: 12.5px;
      textarea {
        min-height: 200px;
        padding: 5px;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .copy-ids {
      p {
        margin-top: 5px;
        cursor: copy;
        a {
          font-size: smaller;
        }
      }
    }
    .button-group {
      display: flex;
      margin: 15px 0 15px 0;
      .button {
        margin: 0 10px 0 0;
      }
    }
  }
}
