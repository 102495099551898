.ct-search-table {
  font-size: 14px;
  overflow-y: scroll;

  &__cell {
    &--small-text {
      font-size: 12px;
    }
  }

  mat-row {
    &:nth-child(odd) {
      background-color: #fcfcfc;
    }
  }

  mat-cell {
    padding: 10px;
  }
}
