.search-input {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
  width: 210px;
  .search-input-icon {
    color: #b4b4b4;
  }
  .search-input-input {
    border: 0;
    /* font-size: 16px; */
    font-size: 14px;
    outline: none;
    color: #6c757d;
    background-color: transparent;
    width: 100%;
    &::placeholder {
      color: darkgray;
    }
  }
}
