.ct-icon-button {
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: dodgerblue;
  color: white;
}
