.button {
  min-width: 9.375rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  min-height: 2.1875rem;
  height: 2.1875rem;
  padding: 0 0.9375rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  user-select: none;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  &--marginRightSm {
    margin-right: var(--spacing-s);
  }
  &--sm {
    min-width: 16px;
  }
  .button-icon {
    margin-right: 1rem;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    user-select: none;
    cursor: not-allowed;
  }
  &--primary {
    color: var(--primary-button-font-color);
    background-color: var(--primary-button-color);
    &:hover {
      background-color: var(--primary-button-hover-color);
    }
  }
  &--secondary {
    border: var(--secondary-button-border);
    background-color: var(--secondary-button-color);
    color: var(--secondary-button-font-color);
    &:hover {
      border: var(--secondary-button-hover-border);
    }
  }
  &--danger {
    color: var(--danger-button-font-color);
    background-color: var(--danger-button-color);
    &:hover {
      background-color: var(--danger-button-hover-color);
    }
  }
  app-shared-btn-loader {
    transform: scale(0.555);
  }
}
