mat-button-toggle {
  height: 30px;
  font-size: 14px;
  font-style: bold;
  display: flex;

  .mat-button-toggle-label-content {
    line-height: 0px !important;
  }
}
