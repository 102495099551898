.ct-slide-toggle {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 800;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  mat-slide-toggle {
    margin-right: 10px;
  }
}
