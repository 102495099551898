dialog {
  width: 480px;
  height: 180px;
  border: 0;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.2);
  z-index: 4;
  .dialog-content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .dialog-header {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 10px;
      h3 {
        font-weight: 400;
      }
      h5 {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
    .dialog-body {
      display: -webkit-box;
      display: flex;
      flex-direction: row;
      padding: 10px;
      justify-content: space-evenly;
    }
  }
}

.tab-title {
  margin-top: 15px;
}

.opportunity {
  height: 100%;
  display: flex;
  flex-direction: column;
  mat-tab-group {
    height: 100%;
    .dialog-tab {
      padding: 20px 10px;
      overflow: hidden;
      height: 100%;
      .horizontal-list {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .button {
        float: right;
      }
      .inputs-container {
        display: flex;
        flex-direction: row;
        .container {
          padding-right: 10px;
          width: 50%;

          &:last-child {
            padding-left: 10px;
          }
        }
        .input-container {
          margin-bottom: 10px;
        }
        .horizontal-list {
          .horizontal-list-item {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .call-length-container {
    margin-top: 10px;
    display: flex;

    .input-container:not(:last-child) {
      margin-right: 10px;
    }
  }
  .honorarium-container {
    display: flex;
    margin: 10px 0;

    .input-container {
      margin-right: 10px;
    }
  }
  .horizontal-list {
    margin-bottom: 10px;
    .horizontal-list-item {
      background: white;
      padding: 8px 13px;
      margin-bottom: 10px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      cursor: pointer;
      .nav-link {
        display: flex;
        align-items: center;
        .nav-link-name {
          font-size: 14px;
          font-weight: 800;
          color: rgb(108, 117, 125);
        }
        .nav-link-icon {
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 10px;
        }
      }
    }
    .close-dialog {
      margin-left: auto;
      cursor: pointer;
    }
  }
  .content-body-grid {
    display: flex;
    padding: 20px;
    height: 630px;
    width: 100%;

    .left-column {
      display: flex;
      flex-direction: column;
      .stats {
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 10px;
        .inner-metrics {
          display: flex;
          .width-wrapper {
            width: 25%;
            margin-bottom: auto;
            margin-top: auto;
          }
          .vr {
            height: 45%;
            width: 1px;
            background-color: #f1f1f1;
          }
        }
      }
      .searches {
        display: flex;
        margin-top: 10px;
        height: 56%;
        flex-direction: column;
      }
    }
    .right-column {
      padding: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      .section {
        position: relative;
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
        min-height: 20%;
        .inputs-container {
          display: flex;
          flex-direction: column;
        }
        .horizontal {
          margin-top: 10px;
          display: flex;
        }
      }
      .hr {
        height: 100%;
        background-color: #f1f1f1;
        width: 1px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

.search-list {
  overflow-y: scroll;
  max-height: 300px;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .search-tile {
    height: 50px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dededede;
    .left-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      .search-title {
        font-weight: 400;
        font-size: 14px;
        width: 97%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .terms {
        color: var(--muted);
        width: 97%;
        white-space: nowrap;
        overflow: hidden;
        font-size: 13px;
        text-overflow: ellipsis;
      }
      .status {
        font-size: 12px;
        text-transform: uppercase;
        color: #bdbdbd;
      }
    }
    .remove-icon {
      transform: scale(0.85);
      color: #ccc;
      cursor: pointer;
    }
  }
}

.opportunity-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
  h2 {
    margin-bottom: var(--spacing-m);
  }
}

.button-div {
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-top: 1px solid #f1f1f1;
}

app-geographic-progress {
  .progress-container {
    display: flex;
    flex-direction: column;
    table {
      width: 100%;
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      th {
        width: 100px;
        padding-left: 10px !important;

        &:not(:first-child) {
          border-left: 1px solid rgba(0, 0, 0, 0.12);
        }

        &:nth-child(n + 3) {
          text-align: right;
          padding-right: 10px !important;

          mat-cell {
            border: none;
          }
        }
      }

      td:nth-child(n + 3) {
        text-align: right;
        padding-right: 10px;

        mat-cell {
          border: none;
        }
      }

      td {
        padding-left: 10px !important;

        &:not(:first-child) {
          border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
      }

      .header-body {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        &:nth-child(even) {
          background: rgb(248, 248, 248);
        }
        &:nth-child(odd) {
          background: #fff;
        }
      }

      .header-row {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        background-color: rgb(248, 248, 248) !important;
      }
    }
    button {
      margin: var(--spacing-m) 0;
    }
  }
}

.engagements-overview-container {
  padding: 20px;
  height: 680px;
  h2 {
    margin-bottom: 20px;
  }
  .engagement-list-container {
    padding-bottom: 50px;
  }
}
