.payments-panel {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .payments-panel-action-bar {
    height: 60px;
    align-items: center;
    justify-content: space-between;
    .payments-panel-checkboxes {
      display: flex;
      .checkbox {
        margin-right: var(--spacing-l);
      }
    }
  }

  .block-card {
    max-height: 85%;
    height: max-content;
    .loader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 50px;
    }

    .mat-table {
      overflow-x: scroll;
    }

    table {
      .mat-row,
      .mat-header-row {
        display: flex;
      }

      @mixin mat-column-defaults {
        padding: 15px 20px;
        width: 100%;
        word-wrap: initial;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }
      .mat-column-Select,
      .mat-column-Edit,
      .mat-column-Add {
        @include mat-column-defaults;
        justify-content: center;
        flex: 0 0 45px;
        .action {
          cursor: pointer;
        }
      }
      .mat-column-expertName,
      .mat-column-email,
      .mat-column-engagementTitle,
      .mat-column-opportunityName,
      .mat-column-owner,
      .mat-column-amountOwed,
      .mat-column-currency,
      .mat-column-dateOfEngagement,
      .mat-column-dateCreated,
      .mat-column-paymentType {
        @include mat-column-defaults;
      }
      .mat-column-paymentStatus {
        @include mat-column-defaults;
        justify-content: center;
        flex: 1 0 190px;
      }

      .mat-header-cell {
        align-items: center;
        font-weight: 800;
      }
    }

    .block-card-footer {
      justify-content: flex-end;
      .input-container {
        margin-right: var(--spacing-m);
      }
    }
  }
}

.payments-csv-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
  .input-container {
    padding: 5px;
  }
  .payment-csv-dialog-button-bar {
    display: flex;
    align-items: center;
    button {
      &:first-child {
        margin-right: var(--spacing-s);
      }
    }
  }
}
