.link-repo-container {
  display: flex;
  flex-direction: column;
  margin: var(--page-margin);
  .block-card-container {
    display: flex;
    .block-card {
      width: 50%;
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
}
