.vertical-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  flex-direction: column;
  .vertical-list-item {
    display: flex;
    align-items: center;
    margin-right: var(--spacing-s);
    margin-bottom: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
}
