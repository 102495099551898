.solicitation {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  position: relative;
  .solicitation-header {
    display: flex;
    align-items: center;
    padding: 0.625rem 1.25rem;
    position: relative;
    justify-content: space-between;
    .solicitation-header-title {
      display: flex;
      align-items: center;
      h2 {
        margin-right: var(--spacing-s) !important;
        &:last-child {
          margin-left: var(--spacing-s) !important;
        }
      }
    }
  }
  .solicitation-recipients {
    display: flex;
    align-items: center;
    /* padding: 0 2rem; */
    border: 1px solid #ccc;
    margin: 0 1.25rem 0.625rem 1.25rem;
    border-radius: 3px;
    .risk-slider {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 6.875rem;
      margin-right: 0.625rem;
      padding: 0.3125rem;
      border-right: 1px solid #ccc;
      .mat-slider-horizontal {
        height: 35px !important;
      }
    }
    app-recipient-list {
      width: 100%;
      ul.emails {
        max-height: 59px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        li {
          display: flex;
          flex-wrap: wrap;
          &:hover {
            cursor: default;
          }
        }
      }

      .chip {
        min-width: 10rem;
        height: 1.25rem;
        padding: 0 0.3125rem 0 1.25rem;
        font-size: 0.8375rem;
        background-color: #f6f6f6;
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin: 0 0.3125rem 0.3125rem 0;
      }
      .unverified-indicator {
        font-weight: 600;
        margin-left: var(--spacing-s);
        margin-right: 0.3125rem;
        transform: scale(1.1);
      }
      .closebtn {
        transform: scale(0.666);
        cursor: pointer;
      }
      .closebtn:hover {
        color: var(--main-text-color);
      }
    }
  }
  .subject-input {
    padding: 0 1.25rem;
    flex-grow: 0;
  }
  .solicitation-body {
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    .hide-menu-div {
      width: 0;
      position: relative;
      transition: all 0.2s ease;
    }
    .show-menu-div {
      width: 670px;
      position: relative;
      transition: all 0.3s ease;
      animation-fill-mode: forwards;
      animation-name: fade;
      animation-duration: 0.7s;
      border-right: 1px solid #ccc;
    }
    .keys-menu {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 1.25rem;
      .keys-menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.3125rem;
      }
      .list {
        flex-grow: 1;
        .list-item {
          flex-direction: column;

          .required {
            background: red;
          }
        }
      }
    }
    .template-menu {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 1.25rem;
      .template-menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.3125rem;
      }
      .template-tabs {
        display: flex;
        align-items: center;
        margin: 0.3125rem 0;
        .thin-button {
          margin-right: var(--spacing-m);
          &.active {
            background-color: cornflowerblue;
            color: white;
            border: 0;
          }
        }
      }
      .search-input {
        margin: 0.3125rem 0;
      }
      .template-list {
        list-style-type: none;
        overflow-y: scroll;
        flex-grow: 1;
        .template-list-item {
          display: flex;
          align-items: center;
          min-height: 3.125rem;
          justify-content: space-between;
          border: 1px solid #ccc;
          margin-bottom: var(--spacing-s);
          margin-right: var(--spacing-s);
          padding: 0.3125rem 0.625rem;
          cursor: pointer;
          &.active-template {
            border: 2px solid cornflowerblue;
          }
          .template-info {
            display: flex;
            flex-direction: column;
            .template-title {
              font-weight: 600;
            }
            .template-description {
              font-size: 0.78125rem;
              color: #79848e;
            }
          }
          .action-items {
            margin-left: auto;

            button {
              cursor: pointer;
              transform: scale(0.86);

              &:disabled {
                color: darkgray;
                cursor: default;
              }

              &:hover:enabled {
                color: darkslategrey;
              }
            }
          }
        }
      }
      .button {
        margin-top: var(--spacing-s);
      }
    }
    .template-form {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      .on-save-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 5px;
        left: 5px;
        bottom: 0px;
        background-color: var(--light);
        box-shadow: 0 0 13px 10px var(--light);
        animation-fill-mode: forwards;
        animation-name: fade;
        animation-duration: 1s;
        animation-direction: reverse;
        z-index: 30;
        p {
          font-size: 28px;
          color: var(--gray);
        }
      }
      .template-form-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.3125rem;
      }
      .template-form-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .inputs {
          display: flex;
          align-items: center;
          .input-container {
            width: 100%;
            margin-right: var(--spacing-s);
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .form-editor {
          overflow: hidden;
          width: 100%;
          height: 100%;
          position: relative;
          pre.ngx-pre {
            font-size: 100%;
            width: 100%;
            height: 100%;
            display: flex;
            overflow: hidden;
            white-space: pre-wrap;
            .form-editor-textarea {
              width: 100%;
              height: 92%;
              padding: 0.625rem;
              border: 1px solid #ccc;
              border-radius: 3px;
              outline: none;
              margin: 0;
            }
          }
        }
      }
      .template-form-actions {
        display: flex;
        align-items: center;
        .button {
          margin-right: var(--spacing-m);
        }
      }
    }
    .editor {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative;
      pre.ngx-pre {
        font-size: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        white-space: pre-wrap;
        .editor-textarea {
          width: 100%;
          height: 92%;
          padding: 0.625rem;
          border: 1px solid #ccc;
          border-radius: 3px;
          margin: 0 var(--spacing-m);
          outline: none;
        }
      }
      .template-form-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--white);
        z-index: 5;
        top: 100%;
        opacity: 0;
        transition: top 0.5s ease 0s;
        animation-fill-mode: forwards;
        animation-name: fade;
        animation-duration: 3s;
        app-new-template {
          display: block;
          position: absolute;
          z-index: 100;
          width: 100%;
          height: 100%;
          overflow: hidden;
          transition: 0.1s;
        }
      }
    }
  }
  .solicitation-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 3.75rem;
    padding: 0 1.25rem;
    border-top: 1px solid #ccc;
    .button-group {
      display: flex;
      align-items: center;
      .error-text {
        margin-right: 5px;
      }
      mat-icon {
        margin-right: 5px;
      }
      .button {
        margin-right: var(--spacing-s);
        &:last-child {
          margin-right: 0;
        }
      }
      .highlight {
        border: 1px solid red;
      }
    }
  }
}

@keyframes fade {
  //put in on file
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.preview {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  section {
    .message {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-image: var(--alt-gradient);
      padding: 10px;
      span {
        width: 100%;
        display: flex;
        align-items: center;
        h5 {
          color: var(--white);
          margin-left: 10px;
          font-size: 1rem;
          font-weight: 400;
        }
      }
      mat-icon {
        width: 25px;
        text-align: right;
        color: var(--white);
        cursor: pointer;
      }
    }
    .inner-preview-container {
      display: flex;
      width: 100%;
      padding: 0;
      .left {
        width: 100px;
        background-color: var(--main-bg-colour);
        color: var(--main-text-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid var(--border-gray);
        &.topBorder {
          border-top: 1px solid var(--grey);
        }
      }
      .right {
        flex: 1;
        color: var(--main-text-color);
        overflow-y: scroll;
        min-height: 47px;
        max-height: 150px;
        padding: 5px;
        &.topBorder {
          border-top: 1px solid var(--grey);
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .preview-body {
    flex-grow: 1;
    article {
      border-top: 1px solid var(--grey);
      padding: 10px;
      z-index: 1;
      overflow: auto;
      p.MsoNormal {
        margin: 0;
      }
    }
  }
  footer {
    padding: 10px 20px;
    border-top: 1px solid var(--grey);
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 3.75rem;
    button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
