.flexRow {
  display: flex;
  &--marginBottomSm {
    margin-bottom: var(--spacing-s);
  }
  &--marginBottomMd {
    margin-bottom: var(--spacing-m);
  }
  &--marginBottomLg {
    margin-bottom: var(--spacing-l);
  }
  &--alignCenter {
    align-items: center;
  }
}
