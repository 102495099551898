.action-container {
  margin-left: 5px;
  margin-top: 5px;

  .ct-icon {
    font-size: 20px;
  }
}

.ct-icon {
  color: rgba(55, 53, 47, 0.6);
  &--selectable {
    cursor: pointer;
  }
}
