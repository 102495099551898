.opp-topbar {
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  box-shadow: var(--box-shadow-medium);
  position: relative;
  z-index: 1;
  .opp-topbar-row {
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
    height: 1.875rem;
    justify-content: space-between;
    .opp-topbar-row-content {
      display: flex;
      align-items: center;
      app-search-select {
        .mat-tree {
          background-color: transparent;
        }
        .drop-down {
          width: 250px;
          height: 650px;
          overflow-y: scroll;
        }
      }
      .back-arrow {
        color: darkgray;
        margin-right: 0.625rem;
      }
      .chevron {
        color: darkgray;
        margin: 0 0.625rem;
      }
      .route-text {
        font-size: 0.9375rem;
        color: #706d6d;
        font-weight: 600;
      }
      .thin-button {
        margin-right: 1.25rem;

        &.filtered {
          background-color: #ccc;
        }
      }

      .master-check-box {
        display: flex;
        align-items: center;
        transform: scale(0.81);
        margin: 0 1.5625rem;
        cursor: pointer;
        color: #bdbdbd;
        .checked {
          color: cornflowerblue;
        }
      }

      .count {
        margin-right: 1.25rem;
      }

      .no-emails-toggle {
        display: flex;
        align-items: center;
        mat-icon {
          transform: scale(0.81);
          cursor: pointer;
          color: #bdbdbd;
          margin-right: 0.3125rem;
          &.checked {
            color: cornflowerblue;
          }
        }
      }
      .affiliation-toggle {
        display: flex;
        align-items: center;
        mat-icon {
          transform: scale(0.81);
          cursor: pointer;
          color: #bdbdbd;
        }
      }
      .more-actions {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        .drop-down {
          right: 0;
          top: 28px;
        }
      }
      .search-input {
        display: flex;
        align-items: center;
        height: 0;
        border: 0;
        background-color: transparent;
        .search-input-icon {
          color: #b4b4b4;
          transform: scale(0.85);
        }
        .search-input-input {
          border: 0;
          font-size: 0.875rem;
          outline: none;
          color: #6c757d;
          background-color: transparent;
        }
      }
    }
  }
}

.phase-menu {
  background-color: #fafafa;
  padding: 4px;
  font-size: 10px;
  z-index: 1000;
  box-shadow: var(--box-shadow-drop-down);
  border-radius: 4px;
  padding: 0.5em 0 0.5em 0;
  animation: fadeIn 0.1s ease-out;
  opacity: 1;
  display: block;
  li {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #333;
    padding: 0.5em 2em 0.5em 0.75em;
    max-width: 18em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background-color: #33a1de;
      color: #fff;
    }
    &:before {
      content: '';
      float: left;
      margin-right: 0.75em;
      width: 0.5em;
      height: 1em;
      display: inline-block;
    }
  }
}

.expert-list-viewport {
  height: calc(100vh - 150px);
  width: 100%;
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  padding: 0 15px !important;
  width: 100%;
  overflow-x: hidden;
}

.expert-list-loader {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-experts-message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  i {
    transform: scale(1.8);
    margin-top: 0.625rem;
    color: darkgrey;
  }
}
