.statCard {
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 1px 9px rgb(144 140 147 / 5%), 0 1px 10px rgb(34 33 35 / 10%);
  width: 100%;
  &__metric {
    display: flex;
    align-items: center;
  }
  &__value {
    color: #6c757d;
    font-size: 35px;
  }
  &__icon {
    color: #6c757d;
    transform: scale(1.3333);
    &--marginLeftMd {
      margin-left: var(--spacing-m);
    }
  }
  &__label {
    color: darkgray;
    font-size: 18px;
  }
}
