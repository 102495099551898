.list-input {
  height: 2.1875rem;
  padding: 0 var(--spacing-xs);
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }

  /* Handle on hover */
  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    li {
      margin-right: var(--spacing-xs);
    }
  }
  .list-input-input {
    border: 0;
    width: 170px;
    font-size: 0.875rem;
    outline: none;
    color: #6c757d;
    background-color: transparent;
  }
}
