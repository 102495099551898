app-expert-responded-detail {
  h3 {
    margin-bottom: 4px;
  }
  .detail-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}

app-responded-dialog {
  height: 100%;
}
