.stepper {
  height: 42px !important;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  .stepper-stage {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    justify-content: space-evenly;
    width: 100%;
    background-color: white;
    border-right: 1px solid rgba(34, 36, 38, 0.15);
    padding: 3px 0;
    &.stepper-stage-focused {
      color: #fff !important;
      background: linear-gradient(to right, #b3ccda, #3884af);
    }
    .stepper-stage-title {
      font-weight: 400;
      font-size: 0.625rem;
      text-transform: uppercase;
      position: relative;
      z-index: 2;
    }
    .stepper-stage-count {
      border-radius: 3px;
      color: #707070;
      border: 1px solid #959595;
      min-width: 2.1875rem;
      height: 1.0625rem;
      padding: 0 3px;
      display: flex;
      font-weight: 400;
      justify-content: center;
      align-items: center;
      font-size: 0.65rem;
      background-color: #fff;
    }
    .stepper-directional-arrow {
      position: absolute;
      width: 15px;
      height: 15px;
      right: -8px;
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      border-right: 1px solid rgba(34, 36, 38, 0.15);
      transform: rotate(45deg);
      background-color: white;
      margin: 0;
      padding: 0;
      z-index: 1;
      &.stepper-directional-arrow-focused {
        background-color: #3884af !important;
      }
    }
  }
}
