.person-initials {
  height: 23px;
  width: 30px;
  border: 1px solid #756e6e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: 800;
  color: #606060;
  font-size: 11px;
  margin-right: 10px;
}
