.select-option-text-input {
  display: flex;
  flex-direction: column;
  .label {
    margin-bottom: 0.2rem;
  }
  .form-field-container {
    font-size: 10px;
  }
  .input {
    height: 2rem;
    padding: 0 0.5rem;
    background-color: rgba(220, 229, 250, 0.1);
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    outline: none;
    width: 100%;
    font-size: var(--font-size-p);
  }
  .empty-selection-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9rem;
    color: #bdbdbd;
    flex-direction: column;
  }
  .selection-list {
    padding: 0;
    list-style-type: none;
    min-height: 9rem;
    li {
      height: 2.75rem;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #dedede;
      position: relative;
      padding: 0 1rem;
      font-size: var(--font-size-p);
      .field-tag {
        width: 6rem;
        font-weight: 600;
      }
      mat-icon {
        position: absolute;
        right: 0;
        transform: scale(0.75);
        color: #c1c1c1;
        cursor: pointer;
      }
    }
  }
}
