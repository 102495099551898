.basicInput {
  display: flex;
  flex-direction: column;
  position: relative;
  &--marginRightMd {
    margin-right: var(--spacing-m);
  }
  &--marginLeftMd {
    margin-left: var(--spacing-m);
  }
  &--width25 {
    width: 25%;
  }
  &--width30 {
    width: 30%;
  }
  &--width50 {
    width: 50%;
  }
  &--width75 {
    width: 75%;
  }
  &--width100 {
    width: 100%;
  }
  &__label {
    font-size: 0.625rem;
    text-transform: uppercase;
    color: var(--font-color);
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    height: 11px;
    mat-icon {
      transform: scale(0.56);
    }
    &--danger {
      color: indianred;
    }
  }
  &__input {
    height: 2rem;
    padding: 0 0.5rem;
    background-color: rgba(220, 229, 250, 0.1);
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    font-size: var(--font-size-p);
    outline: none;
    width: 100%;
    &.danger {
      border: 1px solid indianred;
    }
  }
  &__warning {
    position: absolute;
    bottom: -21px;
  }
}
