.checkbox-bar {
  width: 48%;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-s);
  border: 1px solid #cdcdcd;
  cursor: pointer;
  background-color: #f9f9f9;
  &.checkbox-bar-active {
    background-color: cornflowerblue;
    border: 0;
    p {
      color: white !important;
    }
  }
}

.query-scroll-container {
  overflow-y: auto;
  height: 100%;
  margin-right: -24px;
  padding-right: 24px;
}

.add-condition-button {
  min-width: 60px;
  height: 40px;
  background-color: mediumseagreen;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 15px;
  cursor: pointer;
  mat-icon {
    transform: scale(1.1);
  }
}

.search-filter-form {
  height: 100%;

  .search-filter-header {
    width: 100%;
    padding: 4px 8px;
  }

  .mat-checkbox {
    width: 100%;
    height: 24px;

    .mat-checkbox-layout {
      width: 100%;

      .mat-checkbox-label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .search-filter-name {
          overflow: hidden;
          max-width: 95%;
        }

        .search-filter-count {
          color: #aaa;
        }
      }
    }
  }
}
