.source-pill {
  border: 1px solid;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.8125rem;
  padding: 0 0.625rem;
  height: 18px;
  display: flex;
  width: 57px;
  align-items: center;
  justify-content: center;
  cursor: default;
  &.deep-next-pill {
    color: darkblue;
  }
  &.pdl-commercial-pill {
    color: hotpink;
  }
  &.deep-pill {
    color: slateblue;
  }
  &.hist-pill {
    color: lightseagreen;
  }
  &.netw-pill {
    color: lightcoral;
  }
  &.manu-pill {
    color: darkcyan;
  }
  &.chro-pill {
    color: darkorange;
  }
}
