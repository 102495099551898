.availability-pill {
  background-color: #e3e7ef;
  border-radius: 4px;
  color: #404040;
  min-width: 10rem;
  width: 13.75rem;
  display: -webkit-box;
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.625rem;
  cursor: copy;
  &.requested-call-time {
    border-bottom: 2px solid lightseagreen;
  }
  .availability-pill-text {
    font-size: var(--font-size-pill);
    width: 100%;
    padding: 0 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.new-requested-pill {
  background-color: #fef9e7;
  color: #fbd139;

  .material-icons-outlined {
    font-size: 20px;
  }
}
