@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap");
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Manrope;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 400 20px / 32px Manrope;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Manrope;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Manrope;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Manrope;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Manrope;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 400 14px / 24px Manrope;
  letter-spacing: 0.0179em;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Manrope;
  letter-spacing: 0.0179em;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Manrope;
  letter-spacing: 0.0333em;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 400 112px / 112px Manrope;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Manrope;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Manrope;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Manrope;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Manrope;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 400;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 400;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 400;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 400;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 400 20px / 32px Manrope;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Manrope;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Manrope;
  letter-spacing: "normal";
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Manrope;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 400;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 400;
}

.mat-tab-label, .mat-tab-link {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 400 20px / 32px Manrope;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 400;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 400 14px / 24px Manrope;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Manrope;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

body {
  --primary-color: #228cdb;
  --primary-lighter-color: #1a6ba8;
  --primary-darker-color: #1e2b4e;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: #ffffff;
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: #63e776;
  --accent-lighter-color: #a1f1ad;
  --accent-darker-color: #378142;
  --text-accent-color: rgba(0, 0, 0, 0.87);
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #228cdb;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #63e776;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #228cdb;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #63e776;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #228cdb;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #63e776;
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #228cdb;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #63e776;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #228cdb;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #63e776;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #228cdb;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #63e776;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #228cdb;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #63e776;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #228cdb;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #63e776;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #228cdb;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #63e776;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(34, 140, 219, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(34, 140, 219, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(34, 140, 219, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #228cdb;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(34, 140, 219, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(34, 140, 219, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(34, 140, 219, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(99, 231, 118, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(99, 231, 118, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(99, 231, 118, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #63e776;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(99, 231, 118, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(99, 231, 118, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(99, 231, 118, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 0, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 0, 0, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 0, 0, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #228cdb;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #63e776;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #228cdb;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #63e776;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}

.mat-focused .mat-form-field-required-marker {
  color: #63e776;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #228cdb;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #63e776;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #228cdb;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #63e776;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}

.mat-error {
  color: red;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #228cdb;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #63e776;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #228cdb;
}
.mat-icon.mat-accent {
  color: #63e776;
}
.mat-icon.mat-warn {
  color: red;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #228cdb;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #63e776;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #c4dff2;
}

.mat-progress-bar-buffer {
  background-color: #c4dff2;
}

.mat-progress-bar-fill::after {
  background-color: #228cdb;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #d4f5d9;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #d4f5d9;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #63e776;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbbcbc;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbbcbc;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #228cdb;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #63e776;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #228cdb;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #228cdb;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #63e776;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #63e776;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #228cdb;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #63e776;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #63e776;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(99, 231, 118, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #63e776;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #228cdb;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(34, 140, 219, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #228cdb;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #228cdb;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(34, 140, 219, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #63e776;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(99, 231, 118, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 0, 0, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #228cdb;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #63e776;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: red;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(26, 107, 168, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #228cdb;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(161, 241, 173, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #63e776;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(26, 107, 168, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #228cdb;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(161, 241, 173, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #63e776;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #228cdb;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #63e776;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #63e776;
}

:root {
  --page-margin: 0 1.4rem 1.4rem 1.4rem;
  --box-shadow-dark: 0 2px 6px rgba(0, 0, 0, 0.2);
  --box-shadow-medium: 0 1px 4px rgba(0, 0, 0, 0.14),
    0 1px 4px rgba(0, 0, 0, 0.01);
  --box-shadow-light: 0 1px 3px rgba(0, 0, 0, 0.04),
    0 1px 2px rgba(0, 0, 0, 0.01);
  --box-shadow-drop-down: 0 0 12px rgba(0, 0, 0, 0.25);
  --app-background-color: #f2f4f7;
  --font-size-h1: 1.3rem;
  --font-size-h2: 1.03rem;
  --font-size-h3: 1.03rem;
  --font-size-p: 0.875rem;
  --font-size-pill: 0.75rem;
  --font-weight-h1: 600;
  --font-weight-h2: 700;
  --font-weight-h3: 600;
  --font-weight-p: 400;
  --font-color: #6c757d;
  --email-fail-color: #dc3545;
  --email-success-color: #20c997;
  --email-pending-color: #ffa500;
  --email-default-color: #4f90d1;
  --primary-button-color: #1e87f0;
  --primary-button-hover-color: #0f7ae5;
  --primary-button-font-color: #fff;
  --secondary-button-color: #fff;
  --secondary-button-font-color: black;
  --secondary-button-border: 1px solid #e5e5e5;
  --secondary-button-hover-border: 1px solid #ccc;
  --danger-button-color: indianred;
  --danger-button-hover-color: #ee395b;
  --danger-button-font-color: #fff;
  --spacing-xl: 2.5rem;
  --spacing-l: 1.875rem;
  --spacing-m: 1.25rem;
  --spacing-s: 0.625rem;
  --spacing-xs: 0.3125rem;
}

html {
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-h1);
  color: var(--font-color);
}

h2 {
  text-transform: uppercase;
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-h2);
  color: var(--font-color);
}

h3 {
  font-weight: var(--font-weight-h3);
  font-size: var(--font-size-h3);
  color: var(--font-color);
}

p {
  font-size: var(--font-size-p);
  font-weight: var(--font-weight-p);
  color: var(--font-color);
}
p.danger {
  color: #f44336;
}
p.bold {
  font-weight: 600;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: "Manrope";
}

.thin-button {
  padding: 0 0.75rem;
  min-width: 95px;
  height: 1.25rem;
  font-size: 0.75rem;
  border: 1px solid darkgrey;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;
}
.thin-button:hover {
  border: 1px solid #ccc;
}
.thin-button a {
  text-decoration: none;
  color: inherit;
}
.thin-button .thin-button-icon {
  transform: scale(0.6);
}
.thin-button app-shared-btn-loader {
  transform: scale(0.4);
}

mat-button-toggle {
  height: 30px;
  font-size: 14px;
  font-style: bold;
  display: flex;
}
mat-button-toggle .mat-button-toggle-label-content {
  line-height: 0px !important;
}

.close-icon {
  cursor: pointer;
  user-select: none;
}

.email-status-circle {
  width: 13px;
  min-width: 13px;
  height: 13px;
  border-radius: 50%;
}
.email-status-circle.fail {
  background-color: var(--email-fail-color);
}
.email-status-circle.success {
  background-color: var(--email-success-color);
}
.email-status-circle.pending {
  background-color: var(--email-pending-color);
}
.email-status-circle.default {
  background-color: var(--email-default-color);
}

.stepper {
  height: 42px !important;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
.stepper .stepper-stage {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  width: 100%;
  background-color: white;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  padding: 3px 0;
}
.stepper .stepper-stage.stepper-stage-focused {
  color: #fff !important;
  background: linear-gradient(to right, #b3ccda, #3884af);
}
.stepper .stepper-stage .stepper-stage-title {
  font-weight: 400;
  font-size: 0.625rem;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}
.stepper .stepper-stage .stepper-stage-count {
  border-radius: 3px;
  color: #707070;
  border: 1px solid #959595;
  min-width: 2.1875rem;
  height: 1.0625rem;
  padding: 0 3px;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  font-size: 0.65rem;
  background-color: #fff;
}
.stepper .stepper-stage .stepper-directional-arrow {
  position: absolute;
  width: 15px;
  height: 15px;
  right: -8px;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  transform: rotate(45deg);
  background-color: white;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.stepper .stepper-stage .stepper-directional-arrow.stepper-directional-arrow-focused {
  background-color: #3884af !important;
}

.drop-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fcfcfc;
  padding: 0.625rem;
  box-shadow: var(--box-shadow-drop-down);
  list-style-type: none;
  width: max-content;
  z-index: 100;
  border-radius: 5px;
}
.drop-down .drop-down-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
  color: #6c757d;
  width: 100%;
  cursor: pointer;
}
.drop-down .drop-down-item:last-child {
  margin-bottom: 0;
}
.drop-down .drop-down-item .interactable {
  margin-left: auto;
}
.drop-down .drop-down-item .interactable:hover {
  cursor: pointer;
  color: lightslategrey;
}
.drop-down .drop-down-item .drop-down-item-icon {
  margin-right: var(--spacing-s);
}

.card-statistics-category {
  font-size: 1.0625rem;
  font-weight: 400;
  color: rgb(195, 174, 173);
}

.card-statistics-icon {
  font-size: 24px;
  color: rgb(158, 172, 178);
}

.card-statistics-metric {
  font-size: 1.5625rem;
  font-weight: 800;
  color: rgb(108, 117, 125);
}

.card-statistics-navigation {
  font-size: var(--font-size-p);
  font-weight: 400;
  color: rgb(195, 174, 173);
  border-top: 1px solid rgba(195, 174, 173, 0.3);
  cursor: pointer;
}
.card-statistics-navigation .material-icons {
  font-size: 14px;
  margin-right: 3px;
}

.selected-tz-input {
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
}

.list-input {
  height: 2.1875rem;
  padding: 0 var(--spacing-xs);
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.list-input::-webkit-scrollbar {
  height: 4px;
}
.list-input::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.list-input::-webkit-scrollbar-thumb {
  background: #ccc;
}
.list-input ul {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.list-input ul li {
  margin-right: var(--spacing-xs);
}
.list-input .list-input-input {
  border: 0;
  width: 170px;
  font-size: 0.875rem;
  outline: none;
  color: #6c757d;
  background-color: transparent;
}

.search-input {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
  width: 210px;
}
.search-input .search-input-icon {
  color: #b4b4b4;
}
.search-input .search-input-input {
  border: 0;
  /* font-size: 16px; */
  font-size: 14px;
  outline: none;
  color: #6c757d;
  background-color: transparent;
  width: 100%;
}
.search-input .search-input-input::placeholder {
  color: darkgray;
}

.base-input-input {
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  font-size: var(--font-size-p);
  outline: none;
}
.base-input-input.danger {
  border: 1px solid indianred;
}

.select-option-text-input {
  display: flex;
  flex-direction: column;
}
.select-option-text-input .label {
  margin-bottom: 0.2rem;
}
.select-option-text-input .form-field-container {
  font-size: 10px;
}
.select-option-text-input .input {
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
  width: 100%;
  font-size: var(--font-size-p);
}
.select-option-text-input .empty-selection-list {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  color: #bdbdbd;
  flex-direction: column;
}
.select-option-text-input .selection-list {
  padding: 0;
  list-style-type: none;
  min-height: 9rem;
}
.select-option-text-input .selection-list li {
  height: 2.75rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #dedede;
  position: relative;
  padding: 0 1rem;
  font-size: var(--font-size-p);
}
.select-option-text-input .selection-list li .field-tag {
  width: 6rem;
  font-weight: 600;
}
.select-option-text-input .selection-list li mat-icon {
  position: absolute;
  right: 0;
  transform: scale(0.75);
  color: #c1c1c1;
  cursor: pointer;
}

.country-zone-input {
  display: flex;
  flex-direction: column;
}
.country-zone-input .label {
  margin-bottom: 0.2rem;
}
.country-zone-input .country-zone-input-selects {
  display: flex;
  align-items: center;
}
.country-zone-input .country-zone-input-selects .country-zone-input-select {
  width: 18rem;
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  font-size: var(--font-size-p);
  outline: none;
}
.country-zone-input .country-zone-input-selects .country-zone-input-chevron {
  transform: scale(1.25);
  margin: 0 2rem;
  color: #bdbdbd;
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 0.625rem;
  text-transform: uppercase;
  color: var(--font-color);
}
.label.danger {
  color: indianred;
}

.checkbox {
  display: flex;
  align-items: center;
}
.checkbox .label {
  margin-left: 0.5rem;
}

.textarea-input {
  padding: 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
}

.timezone-selector-input-container {
  position: relative;
}
.timezone-selector-input-container .timezone-selector-input {
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  font-size: var(--font-size-p);
  outline: none;
  width: 100%;
}
.timezone-selector-input-container .timezone-selector-input.danger {
  border: 1px solid indianred;
}

.source-pill {
  border: 1px solid;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.8125rem;
  padding: 0 0.625rem;
  height: 18px;
  display: flex;
  width: 57px;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.source-pill.deep-next-pill {
  color: darkblue;
}
.source-pill.pdl-commercial-pill {
  color: hotpink;
}
.source-pill.deep-pill {
  color: slateblue;
}
.source-pill.hist-pill {
  color: lightseagreen;
}
.source-pill.netw-pill {
  color: lightcoral;
}
.source-pill.manu-pill {
  color: darkcyan;
}
.source-pill.chro-pill {
  color: darkorange;
}

.availability-pill {
  background-color: #e3e7ef;
  border-radius: 4px;
  color: #404040;
  min-width: 10rem;
  width: 13.75rem;
  display: -webkit-box;
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.625rem;
  cursor: copy;
}
.availability-pill.requested-call-time {
  border-bottom: 2px solid lightseagreen;
}
.availability-pill .availability-pill-text {
  font-size: var(--font-size-pill);
  width: 100%;
  padding: 0 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-requested-pill {
  background-color: #fef9e7;
  color: #fbd139;
}
.new-requested-pill .material-icons-outlined {
  font-size: 20px;
}

.expertise-tag {
  cursor: pointer;
  border-radius: 3px;
  font-size: var(--font-size-pill);
  height: 1rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  background-color: #9c96c3;
  color: white;
  margin-bottom: 0.1875rem;
  margin-right: 0.1875rem;
}

.email-pill {
  background-color: #f2f2f2;
  border-radius: 20px;
  color: #404040;
  min-width: 10rem;
  width: 13.75rem;
  display: -webkit-box;
  display: flex;
  align-items: center;
  height: 1.25rem;
  padding: 0 0.625rem;
  cursor: copy;
}
.email-pill.email-pill-selected {
  border: 2px solid cornflowerblue;
}
.email-pill .email-pill-text {
  font-size: var(--font-size-pill);
  width: 100%;
  padding: 0 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-pill .email-status-circle {
  cursor: default;
  margin-left: 0.3125rem;
}
.email-pill.highlight-danger {
  background-color: #d13026;
  color: #fff;
}

.tz-list-item {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.tz-list-item .tz-list-item-country {
  font-size: 10px;
  font-weight: 400;
  color: #a5a5a5;
  text-transform: uppercase;
}
.tz-list-item .tz-list-item-timezone {
  font-weight: 400;
  color: #6c757d;
  font-size: 14px;
}

.page-header {
  height: 3.75rem;
  display: flex;
  align-items: center;
}

.mat-divider-thick {
  border-top-width: 10px !important;
}

mat-panel-title {
  width: 100%;
}
mat-panel-title .engagement-title {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
mat-panel-title .engagement-title span {
  font-size: 18px;
  color: royalblue;
  margin-right: 10px;
}
mat-panel-title .engagement-title .engagement-title-payment-status {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
mat-panel-title .engagement-title .engagement-title-payment-status mat-icon {
  color: seagreen;
  margin-right: var(--spacing-s);
}
mat-panel-title .engagement-title p {
  font-weight: 800;
}

.billing-form.completed-payment-opacity {
  opacity: 0.4;
  pointer-events: none;
}
.billing-form .payment-status {
  pointer-events: none;
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-m);
}
.billing-form .payment-status p {
  margin-right: var(--spacing-s);
}
.billing-form .payment-status mat-radio-button {
  margin-right: var(--spacing-s);
}
.billing-form .input-row {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-m);
}
.billing-form .input-row .input-container {
  margin-right: var(--spacing-s);
  width: 30%;
}
.billing-form .input-row .input-container.width-100 {
  width: 100%;
}
.billing-form .input-row .input-container:last-child {
  margin-right: 0;
}
.billing-form .payment-active-toggle-box {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #f2f2f2;
  padding: 0 var(--spacing-m);
  border: 1px solid #ccc;
  margin-bottom: var(--spacing-l);
}
.billing-form .payment-active-toggle-box mat-icon {
  transform: scale(1.3);
}
.billing-form .payment-active-toggle-box mat-icon.payment-active-icon {
  color: cornflowerblue;
}
.billing-form .payment-active-toggle-box mat-icon.payment-inactive-icon {
  color: lightgray;
}
.billing-form .payment-active-toggle-box p {
  margin: 0 20px;
}
.billing-form .payment-active-toggle-box .toggle-container {
  display: flex;
  align-items: center;
}
.billing-form .payment-active-toggle-box .toggle-container p {
  margin: 0 10px;
}
.billing-form .engagement-action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.billing-form .engagement-action-buttons p {
  flex-grow: 1;
}
.billing-form .engagement-action-buttons button {
  margin-right: 20px;
}
.billing-form .engagement-action-buttons button:last-child {
  margin-right: 0;
}

.table-opportunity-data-view {
  width: 100%;
}
.table-opportunity-data-view .mat-header-row .mat-column-options {
  width: 10%;
  text-align: right;
}
.table-opportunity-data-view .mat-row.is-editing {
  background-color: #ccc;
  font-weight: bold;
}
.table-opportunity-data-view .mat-row .mat-column-options {
  text-align: right;
}
.table-opportunity-data-view .mat-row .mat-column-options .action-button {
  cursor: pointer;
  margin: 0;
  padding: 0;
  min-width: 25px;
}

.table-expert-targets .mat-header-row .mat-column-segment {
  width: 15%;
}
.table-expert-targets .mat-header-row .mat-column-amount {
  width: 5%;
}
.table-expert-targets .mat-header-row .mat-column-unitsPerEngagement {
  width: 5%;
}
.table-expert-targets .mat-header-row .mat-column-owner {
  width: 15%;
}
.table-expert-targets .mat-header-row .mat-column-emailSubject {
  width: 20%;
}
.table-expert-targets .mat-header-row .mat-column-screenerLink {
  width: 40%;
}

.table-currency-honorarium .mat-header-row .mat-column-currency {
  width: 30%;
}
.table-currency-honorarium .mat-header-row .mat-column-starting {
  width: 30%;
}
.table-currency-honorarium .mat-header-row .mat-column-max {
  width: 30%;
}

.form-opportunity-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}
.form-opportunity-container input::placeholder {
  color: rgba(228, 220, 220, 0.7) !important;
}
.form-opportunity-container .form-opportunity-inline {
  display: flex;
  align-items: flex-end;
}
.form-opportunity-container .form-opportunity-inline .input-container {
  margin-right: 10px;
}
.form-opportunity-container .form-opportunity-inline button {
  margin-right: 10px;
}
.form-opportunity-container .form-opportunity-inline button:last-child {
  margin-right: 0;
}

.list {
  list-style-type: none;
  padding: 0;
}
.list__item {
  display: flex;
  border-bottom: 1px solid rgba(222, 222, 222, 0.8705882353);
  padding: 10px 0;
  align-items: center;
}
.list__item--clickable {
  cursor: pointer;
}

.person-initials {
  height: 23px;
  width: 30px;
  border: 1px solid #756e6e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: 800;
  color: #606060;
  font-size: 11px;
  margin-right: 10px;
}

.ct-generic-chip {
  background-color: #e6e6e6;
  padding: 3px 10px;
  border-radius: 4px;
  display: flex;
  min-height: 24px;
  align-items: center;
  font-size: 14px;
  height: max-content;
}
.ct-generic-chip--small {
  min-height: 19px;
  font-size: 0.75rem;
  margin-bottom: 5px;
}
.ct-generic-chip__text {
  font-weight: 400;
}
.ct-generic-chip__icon {
  margin-left: 9px;
  color: #737272;
  cursor: pointer;
  transform: scale(0.76) rotate(0.3deg);
}

.ct-emailChip {
  background-color: #f2f2f2;
  border-radius: 20px;
  color: #404040;
  min-width: 10rem;
  width: 13.75rem;
  display: -webkit-box;
  display: flex;
  align-items: center;
  height: 1.25rem;
  padding: 0 0.625rem;
  cursor: copy;
}
.ct-emailChip--selected {
  border: 2px solid cornflowerblue;
}
.ct-emailChip__text {
  font-size: var(--font-size-pill);
  width: 100%;
  padding: 0 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ct-emailChip__icon {
  transform: scale(0.845);
}
.ct-emailChip__icon--valid {
  color: #95d145;
}
.ct-emailChip__icon--pending {
  color: #f5c600;
}
.ct-emailChip__icon--invalid {
  color: #d62f2c;
  transform: scale(0.845) rotate(45deg);
}
.ct-emailChip__label {
  position: absolute;
  font-size: 0.65rem;
  font-weight: 400;
  color: #a5a5a5;
  text-transform: uppercase;
  top: 1px;
}

.card {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 1px 9px rgba(144, 140, 147, 0.05), 0 1px 10px rgba(34, 33, 35, 0.1);
  overflow: hidden;
  height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.card__header {
  padding: 10px 20px;
}
.card__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
}
.card__content--overflow {
  overflow-y: scroll;
}
.card__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card__actions {
  padding: 20px;
}
.card--width100 {
  width: 100%;
}
.card--marginRightLg {
  margin-right: var(--spacing-l);
}

mat-card.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 1px 9px rgba(144, 140, 147, 0.05), 0 1px 10px rgba(34, 33, 35, 0.1);
  border-radius: 10px;
  padding: 24px;
}

.statCard {
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 1px 9px rgba(144, 140, 147, 0.05), 0 1px 10px rgba(34, 33, 35, 0.1);
  width: 100%;
}
.statCard__metric {
  display: flex;
  align-items: center;
}
.statCard__value {
  color: #6c757d;
  font-size: 35px;
}
.statCard__icon {
  color: #6c757d;
  transform: scale(1.3333);
}
.statCard__icon--marginLeftMd {
  margin-left: var(--spacing-m);
}
.statCard__label {
  color: darkgray;
  font-size: 18px;
}

.button {
  min-width: 9.375rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  min-height: 2.1875rem;
  height: 2.1875rem;
  padding: 0 0.9375rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  user-select: none;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}
.button--marginRightSm {
  margin-right: var(--spacing-s);
}
.button--sm {
  min-width: 16px;
}
.button .button-icon {
  margin-right: 1rem;
}
.button:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  user-select: none;
  cursor: not-allowed;
}
.button--primary {
  color: var(--primary-button-font-color);
  background-color: var(--primary-button-color);
}
.button--primary:hover {
  background-color: var(--primary-button-hover-color);
}
.button--secondary {
  border: var(--secondary-button-border);
  background-color: var(--secondary-button-color);
  color: var(--secondary-button-font-color);
}
.button--secondary:hover {
  border: var(--secondary-button-hover-border);
}
.button--danger {
  color: var(--danger-button-font-color);
  background-color: var(--danger-button-color);
}
.button--danger:hover {
  background-color: var(--danger-button-hover-color);
}
.button app-shared-btn-loader {
  transform: scale(0.555);
}

.ct-icon-button {
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: dodgerblue;
  color: white;
}

.basicInput {
  display: flex;
  flex-direction: column;
  position: relative;
}
.basicInput--marginRightMd {
  margin-right: var(--spacing-m);
}
.basicInput--marginLeftMd {
  margin-left: var(--spacing-m);
}
.basicInput--width25 {
  width: 25%;
}
.basicInput--width30 {
  width: 30%;
}
.basicInput--width50 {
  width: 50%;
}
.basicInput--width75 {
  width: 75%;
}
.basicInput--width100 {
  width: 100%;
}
.basicInput__label {
  font-size: 0.625rem;
  text-transform: uppercase;
  color: var(--font-color);
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  height: 11px;
}
.basicInput__label mat-icon {
  transform: scale(0.56);
}
.basicInput__label--danger {
  color: indianred;
}
.basicInput__input {
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  font-size: var(--font-size-p);
  outline: none;
  width: 100%;
}
.basicInput__input.danger {
  border: 1px solid indianred;
}
.basicInput__warning {
  position: absolute;
  bottom: -21px;
}

.ct-chipList .mat-chip {
  height: 20px;
  min-height: 0;
}
.ct-chipList .mat-chip.fail {
  color: white;
  background-color: var(--email-fail-color);
}
.ct-chipList .mat-chip.success {
  color: white;
  background-color: var(--email-success-color);
}
.ct-chipList .mat-chip.pending {
  color: white;
  background-color: var(--email-pending-color);
}
.ct-chipList .mat-chip.default {
  color: white;
  background-color: var(--email-default-color);
}

.ct-tableEmptyState {
  background-color: #eee;
  border: 1px solid #dedede;
  border-top: 0;
  padding: 24px;
}
.ct-tableEmptyState .ct-tableEmptyStateHeader {
  font-size: 24px;
}

.ct-noPaddingTable.mat-table {
  width: 100%;
}
.ct-noPaddingTable.mat-table .mat-header-row .mat-header-cell {
  padding: 0;
}
.ct-noPaddingTable.mat-table .mat-header-row .mat-column-options {
  text-align: right;
}
.ct-noPaddingTable.mat-table .mat-row .mat-cell {
  padding: 0 16px 0 0;
}
.ct-noPaddingTable.mat-table .mat-row .mat-cell:last-child {
  padding: 0;
}
.ct-noPaddingTable.mat-table .mat-row .mat-column-options {
  text-align: right;
}
.ct-noPaddingTable.mat-table .mat-row:hover {
  background: whitesmoke;
}

.ct-searchInput {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 5px 0 5px;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
}
.ct-searchInput__icon {
  color: #b4b4b4;
}
.ct-searchInput__input {
  border: 0;
  font-size: 14px;
  outline: none;
  color: #6c757d;
  background-color: transparent;
  width: 100%;
  font-weight: 600;
}
.ct-searchInput__input::placeholder {
  color: darkgray;
}

.ct-modalContainer {
  padding: 24px;
}

.ct-page-container {
  margin: 24px;
}

mat-list-item {
  border-bottom: 1px solid #f2f2f2;
}
mat-list-item:nth-child(odd) {
  background-color: #fcfcfc;
}

.mat-list-item-content {
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ct-list-item__text {
  font-size: 14px;
  font-weight: 400;
}
.ct-list-item__text--dimmed {
  color: #aaa;
}
.ct-list-item__icon {
  margin-left: 9px;
  color: #737272;
  cursor: pointer;
  transform: scale(0.76) rotate(0.3deg);
}
.ct-list-item__icon--dimmed {
  color: #aaa;
}
.ct-list-item__icon--loading {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ct-engagementIndicator {
  transform: scale(0.9);
  cursor: default;
}

.action-container {
  margin-left: 5px;
  margin-top: 5px;
}
.action-container .ct-icon {
  font-size: 20px;
}

.ct-icon {
  color: rgba(55, 53, 47, 0.6);
}
.ct-icon--selectable {
  cursor: pointer;
}

.ct-opportunity-list-item {
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px !important;
}
.ct-opportunity-list-item__header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.ct-opportunity-list-item__identification {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.ct-opportunity-list-item__title {
  height: 23px;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 14px;
  color: #56595a !important;
}
.ct-opportunity-list-item__ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ct-opportunity-list-item__pod-list {
  display: flex;
  align-items: center;
}
.ct-opportunity-list-item__actions {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ct-opportunity-list-item__icon:not(:last-child) {
  margin-right: 20px;
}
.ct-opportunity-list-item__content {
  width: 100%;
  overflow-x: auto;
}

.ct-count {
  display: flex;
  align-items: center;
  color: darkgray;
  min-width: 90px;
}
.ct-count__icon {
  transform: scale(0.85);
}
.ct-count__value {
  font-size: 0.75rem;
  font-weight: 600;
}

.ct-search-table {
  font-size: 14px;
  overflow-y: scroll;
}
.ct-search-table__cell--small-text {
  font-size: 12px;
}
.ct-search-table mat-row:nth-child(odd) {
  background-color: #fcfcfc;
}
.ct-search-table mat-cell {
  padding: 10px;
}

.ct-slide-toggle {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 800;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}
.ct-slide-toggle mat-slide-toggle {
  margin-right: 10px;
}

.connect-grid {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 16rem 1fr;
  grid-template-rows: 50px 1fr;
}
.connect-grid__sidebar {
  grid-row: 1/3;
  grid-column: 1/2;
}
.connect-grid__body {
  grid-row: 2/3;
  grid-column: 2/3;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.connect-grid__body::-webkit-scrollbar {
  width: 10px;
}
.connect-grid__body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.connect-grid__body::-webkit-scrollbar-thumb {
  background: #888;
}
.connect-grid__body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.connect-grid__topbar {
  grid-row: 1/2;
  grid-column: 2/3;
}

.horizontal-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
}
.horizontal-list .horizontal-list-item {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-s);
  margin-bottom: 3px;
}
.horizontal-list .horizontal-list-item:last-child {
  margin-right: 0;
}

.vertical-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  flex-direction: column;
}
.vertical-list .vertical-list-item {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-s);
  margin-bottom: 3px;
}
.vertical-list .vertical-list-item:last-child {
  margin-right: 0;
}

.block-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow-dark);
  padding: 0 1.25rem 1.25rem 1.25rem;
  height: 100%;
}
.block-card .block-card-header {
  min-height: 3.25rem;
  display: flex;
  align-items: center;
}
.block-card .search-input {
  width: 13rem;
}
.block-card .input-container {
  width: 18.75rem;
}
.block-card .card-body {
  height: 100%;
}
.block-card .block-card-footer {
  min-height: 3.75rem;
  display: flex;
  align-items: flex-end;
}

.form {
  display: flex;
  flex-direction: column;
}
.form .input-container {
  margin-bottom: var(--spacing-s);
  padding: 0;
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
}

.side-dialog {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.side-dialog .top .input-container {
  margin-top: 12.5px;
}
.side-dialog .top .input-container textarea {
  min-height: 200px;
  padding: 5px;
}
.side-dialog .bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.side-dialog .bottom .copy-ids p {
  margin-top: 5px;
  cursor: copy;
}
.side-dialog .bottom .copy-ids p a {
  font-size: smaller;
}
.side-dialog .bottom .button-group {
  display: flex;
  margin: 15px 0 15px 0;
}
.side-dialog .bottom .button-group .button {
  margin: 0 10px 0 0;
}

.input-container {
  display: flex;
  flex-direction: column;
}
.input-container .label {
  margin-bottom: 0.2rem;
}

.flexRow {
  display: flex;
}
.flexRow--marginBottomSm {
  margin-bottom: var(--spacing-s);
}
.flexRow--marginBottomMd {
  margin-bottom: var(--spacing-m);
}
.flexRow--marginBottomLg {
  margin-bottom: var(--spacing-l);
}
.flexRow--alignCenter {
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexColumn--marginRightMd {
  margin-right: var(--spacing-m);
}
.flexColumn--marginLeftMd {
  margin-left: var(--spacing-m);
}
.flexColumn--width25 {
  width: 25%;
}
.flexColumn--width50 {
  width: 50%;
}
.flexColumn--width100 {
  width: 100%;
}

.topbar {
  display: flex;
  align-items: center;
}
.topbar__menuIcons {
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
  color: rgba(55, 53, 47, 0.8);
}
.topbar__menuIcons:hover {
  background-color: #f1efef;
}
.topbar .bell {
  margin-left: auto;
  cursor: pointer;
  background-color: #0071ec;
  color: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

mat-toolbar {
  background-color: #fff !important;
  height: 48px !important;
  min-height: 48px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08), 0 1px 10px rgba(0, 0, 0, 0.01);
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.navMenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navMenu__img {
  width: 165px;
  margin-bottom: 10px;
}
.navMenu__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}
.navMenu__link {
  display: flex;
  height: 30px;
  font-weight: 800;
  font-size: 14px;
  align-items: center;
  color: rgba(55, 53, 47, 0.6);
  margin-bottom: 10px;
  padding: 0 20px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
.navMenu__link mat-icon {
  transform: scale(0.8) rotate(0.3deg);
  margin-right: 10px;
}
.navMenu__link:hover {
  background-color: #f1f1f1;
}
.navMenu__link.active {
  color: #4e4e4e;
}
.navMenu__footer {
  width: 100%;
  padding: 20px;
}
.navMenu__footer button {
  width: 100%;
}

mat-sidenav {
  background-color: #fdfdfd;
  width: 180px;
}

mat-sidenav-container {
  height: 100vh;
}

.calls-container {
  display: flex;
  flex-direction: column;
  margin: var(--page-margin);
}

.link-repo-container {
  display: flex;
  flex-direction: column;
  margin: var(--page-margin);
}
.link-repo-container .block-card-container {
  display: flex;
}
.link-repo-container .block-card-container .block-card {
  width: 50%;
}
.link-repo-container .block-card-container .block-card:first-child {
  margin-right: 2rem;
}

.dashboard {
  display: flex;
  flex-direction: column;
  margin: var(--page-margin);
}

.clients-container {
  display: flex;
  margin: var(--page-margin);
}
.clients-container .clients-list {
  width: 25%;
  height: 85vh;
  position: relative;
}
.clients-container .clients-list cdk-virtual-scroll-viewport {
  height: 100%;
}
.clients-container app-scintillating-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.clients-container .clients-content {
  width: 100%;
  margin: 10px;
}
.clients-container .clients-card-container {
  display: flex;
  margin: 10px 10px 10px 0px;
  width: 100%;
}
.clients-container .clients-card-container .block-card {
  flex-direction: column;
  width: 100%;
  margin-right: 2rem;
}
.clients-container .clients-card-container .block-card:last-child {
  margin-right: 0;
}
.clients-container .clients-card-container .block-card .block-card-header:first-child {
  justify-content: space-between;
}
.clients-container .clients-card-container .block-card .sub-title {
  display: flex;
}
.clients-container .clients-card-container .block-card .sub-title .material-icons-outlined {
  margin-right: 10px;
}
.clients-container .clients-card-container .block-card .opportunity-container {
  height: 50vh;
  overflow-y: visible;
}

.no-selection-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-selection-div h2 {
  margin-bottom: 0.5rem;
}
.no-selection-div mat-icon {
  transform: scale(1.8);
  margin-top: 1rem;
  color: darkgrey;
}

.payments-panel {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.payments-panel .payments-panel-action-bar {
  height: 60px;
  align-items: center;
  justify-content: space-between;
}
.payments-panel .payments-panel-action-bar .payments-panel-checkboxes {
  display: flex;
}
.payments-panel .payments-panel-action-bar .payments-panel-checkboxes .checkbox {
  margin-right: var(--spacing-l);
}
.payments-panel .block-card {
  max-height: 85%;
  height: max-content;
}
.payments-panel .block-card .loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50px;
}
.payments-panel .block-card .mat-table {
  overflow-x: scroll;
}
.payments-panel .block-card table .mat-row,
.payments-panel .block-card table .mat-header-row {
  display: flex;
}
.payments-panel .block-card table .mat-column-Select,
.payments-panel .block-card table .mat-column-Edit,
.payments-panel .block-card table .mat-column-Add {
  padding: 15px 20px;
  width: 100%;
  word-wrap: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 45px;
}
.payments-panel .block-card table .mat-column-Select .action,
.payments-panel .block-card table .mat-column-Edit .action,
.payments-panel .block-card table .mat-column-Add .action {
  cursor: pointer;
}
.payments-panel .block-card table .mat-column-expertName,
.payments-panel .block-card table .mat-column-email,
.payments-panel .block-card table .mat-column-engagementTitle,
.payments-panel .block-card table .mat-column-opportunityName,
.payments-panel .block-card table .mat-column-owner,
.payments-panel .block-card table .mat-column-amountOwed,
.payments-panel .block-card table .mat-column-currency,
.payments-panel .block-card table .mat-column-dateOfEngagement,
.payments-panel .block-card table .mat-column-dateCreated,
.payments-panel .block-card table .mat-column-paymentType {
  padding: 15px 20px;
  width: 100%;
  word-wrap: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.payments-panel .block-card table .mat-column-paymentStatus {
  padding: 15px 20px;
  width: 100%;
  word-wrap: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 190px;
}
.payments-panel .block-card table .mat-header-cell {
  align-items: center;
  font-weight: 800;
}
.payments-panel .block-card .block-card-footer {
  justify-content: flex-end;
}
.payments-panel .block-card .block-card-footer .input-container {
  margin-right: var(--spacing-m);
}

.payments-csv-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
}
.payments-csv-dialog .input-container {
  padding: 5px;
}
.payments-csv-dialog .payment-csv-dialog-button-bar {
  display: flex;
  align-items: center;
}
.payments-csv-dialog .payment-csv-dialog-button-bar button:first-child {
  margin-right: var(--spacing-s);
}

.statistics-container {
  display: flex;
  flex-direction: column;
  margin: var(--page-margin);
}
.statistics-container .search-input {
  width: 18.75rem;
}
.statistics-container .selection-list {
  display: flex;
  flex-direction: row;
  min-height: 1.875rem;
  flex-wrap: wrap;
  margin: 0.625rem 0 0.3125rem 0;
}
.statistics-container .selection-list li {
  height: 1.5625rem;
  padding: 0 0.3125rem 0 var(--spacing-m);
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0 var(--spacing-m) 0.3125rem 0;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  font-size: var(--font-size-p);
}
.statistics-container .selection-list li mat-icon {
  transform: scale(0.7);
  cursor: pointer;
}

.percentage-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.percentage-box .stat-span {
  font-weight: 400;
  font-size: 1.34375rem;
}
.percentage-box .label {
  font-size: var(--font-size-p);
  font-weight: 400;
}

.tab-buttons {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
  border-bottom: 1px solid #dedede;
}
.tab-buttons .tab-button {
  cursor: pointer;
  font-size: 18px;
  margin-right: var(--spacing-l);
  color: #7f7f7f;
}
.tab-buttons .tab-button.active {
  border-bottom: 3px solid steelblue;
  color: #404040;
}

.expert-profile {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.expert-profile .expert-profile-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  padding: var(--spacing-m);
}
.expert-profile .expert-profile-content .tab-content {
  overflow-y: auto;
}
.expert-profile .expert-profile-content .expert-profile-header {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: var(--spacing-m);
}
.expert-profile .expert-profile-content .expert-profile-header h1 {
  margin-right: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-profile-header .rejected {
  margin-right: var(--spacing-xl);
}
.expert-profile .expert-profile-content .expert-profile-header .status-box {
  height: 30px;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-profile-header .status-box mat-icon {
  margin-right: var(--spacing-xs);
  transform: scale(0.8666);
}
.expert-profile .expert-profile-content .expert-profile-header .close-icon {
  position: absolute;
  right: 0;
}
.expert-profile .expert-profile-content .expert-missing-timezone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expert-profile .expert-profile-content .expert-missing-timezone h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: var(--spacing-l);
}
.expert-profile .expert-profile-content .expert-missing-timezone .input-container {
  width: 300px;
}
.expert-profile .expert-profile-content .expert-missing-timezone .input-container .timezone-selector-input {
  width: 315px;
}
.expert-profile .expert-profile-content .expert-missing-timezone .input-container .drop-down {
  max-height: 260px;
  overflow-y: scroll;
}
.expert-profile .expert-profile-content .expert-availability {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-l);
}
.expert-profile .expert-profile-content .expert-availability h2 {
  margin-bottom: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-availability .update-call-time-icon {
  transform: scale(0.7);
  position: relative;
  top: 5px;
  left: 3px;
  cursor: pointer;
}
.expert-profile .expert-profile-content .expert-availability .update-call-time-input-container input {
  margin-right: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-availability .clear-update-call-time-icon {
  transform: scale(0.7);
  position: relative;
  top: 9px;
  left: 3px;
  cursor: pointer;
}
.expert-profile .expert-profile-content .expert-availability .update-call-time-input-container {
  display: flex;
}
.expert-profile .expert-profile-content .expert-availability .pagination-label {
  margin-bottom: var(--spacing-s);
}
.expert-profile .expert-profile-content .recording-box {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #f2f2f2;
  padding: 0 var(--spacing-m);
  border: 1px solid #ccc;
  margin-bottom: var(--spacing-l);
}
.expert-profile .expert-profile-content .recording-box mat-icon {
  transform: scale(1.5);
  margin-right: var(--spacing-m);
}
.expert-profile .expert-profile-content .recording-box mat-icon.disabled-recording-icon {
  color: lightslategray;
}
.expert-profile .expert-profile-content .recording-box mat-icon.enabled-recording-icon {
  color: darkseagreen;
}
.expert-profile .expert-profile-content .recording-box p {
  margin-right: var(--spacing-m);
}
.expert-profile .expert-profile-content .recording-box .toggle-label {
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-details {
  margin-bottom: 60px;
}
.expert-profile .expert-profile-content .expert-details h2 {
  margin-bottom: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-details .details-flex-row {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-m);
}
.expert-profile .expert-profile-content .expert-details .details-flex-row .input-container {
  margin-right: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-details .details-flex-row .input-container:last-child {
  margin-right: 0;
}
.expert-profile .expert-profile-content .expert-details .details-flex-row .input-container .file-list-container {
  display: flex;
  align-items: center;
}
.expert-profile .expert-profile-content .expert-details .details-flex-row .input-container .file-list-container .list-input {
  width: 100%;
  margin-right: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-details .details-flex-row app-timezone-selector .drop-down {
  right: 0;
  height: 24rem;
  overflow-y: scroll;
}
.expert-profile .expert-profile-content .expert-details .details-flex-row .width-50 {
  width: 50%;
}
.expert-profile .expert-profile-content .expert-details .details-flex-row .width-25 {
  width: 25%;
}
.expert-profile .expert-profile-content .expert-biography {
  display: flex;
  flex-direction: column;
}
.expert-profile .expert-profile-content .expert-biography .bio-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-biography .bio-header .links {
  display: flex;
  align-items: center;
}
.expert-profile .expert-profile-content .expert-biography .bio-header .links button,
.expert-profile .expert-profile-content .expert-biography .bio-header .links app-expert-previous-bio-button {
  margin-right: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-biography .bio-header .links button:last-child,
.expert-profile .expert-profile-content .expert-biography .bio-header .links app-expert-previous-bio-button:last-child {
  margin-right: 0;
}
.expert-profile .expert-profile-content .expert-biography .bio {
  height: 500px;
  margin-bottom: 50px;
}
.expert-profile .expert-profile-content .expert-biography .prev-and-gen-bio {
  display: flex;
  flex-direction: column;
  min-height: 250px;
}
.expert-profile .expert-profile-content .expert-biography .prev-and-gen-bio .prev-and-gen-bio-actions {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-biography .prev-and-gen-bio .prev-and-gen-bio-actions button {
  margin-left: var(--spacing-s);
  position: relative;
  top: 10px;
}
.expert-profile .expert-profile-content .expert-biography .bio-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-biography .bio-actions button {
  margin-right: var(--spacing-s);
}
.expert-profile .expert-profile-content .expert-biography .bio-actions button:last-child {
  margin-right: 0;
}
.expert-profile .expert-profile-content .expert-missing-currency {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expert-profile .expert-profile-content .expert-missing-currency h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: var(--spacing-l);
}
.expert-profile .expert-profile-content .expert-missing-currency .input-area {
  display: flex;
  align-items: flex-end;
}
.expert-profile .expert-profile-content .expert-missing-currency .input-area .input-container {
  width: 300px;
  margin-right: var(--spacing-m);
}
.expert-profile .expert-profile-content .expert-missing-currency .input-area .input-container .drop-down {
  max-height: 260px;
  overflow-y: scroll;
  top: 110px;
}
.expert-profile .expert-profile-content .expert-billing .billing-actions,
.expert-profile .expert-profile-content .expert-billing .billing-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}
.expert-profile .expert-profile-content .expert-billing .billing-info {
  justify-content: flex-end;
}
.expert-profile .expert-profile-content .expert-billing .billing-info .input-row {
  display: flex;
}
.expert-profile .expert-profile-content .expert-billing .billing-info .input-row .input-container {
  margin-right: 20px;
}
.expert-profile .expert-profile-content .expert-billing .billing-info .input-row .input-container:last-child {
  margin-right: 0;
}
.expert-profile .expert-profile-content .expert-billing .billing-actions {
  justify-content: flex-start;
}
.expert-profile .expert-profile-content .expert-billing .billing-actions .add-engagement-buttons {
  display: flex;
  align-items: center;
}
.expert-profile .expert-profile-content .expert-billing .billing-actions .add-engagement-buttons button {
  border: 1px solid mediumslateblue;
  color: mediumblue;
  margin-right: 1rem;
}
.expert-profile .expert-profile-content .expert-billing .summary {
  margin: 40px 0 80px 0;
}
.expert-profile .expert-profile-content .expert-billing .summary .input-container {
  margin-bottom: var(--spacing-m);
}
.expert-profile .expert-profile-content .expert-billing .summary .input-row {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-m);
}
.expert-profile .expert-profile-content .expert-billing .summary .input-row .input-container {
  margin-right: var(--spacing-s);
  width: 30%;
  margin-bottom: 0;
}
.expert-profile .expert-profile-content .expert-billing .summary .input-row .input-container:last-child {
  margin-right: 0;
}
.expert-profile .expert-profile-footer {
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-m);
  border-top: 1px solid #f1f1f1;
  justify-content: flex-end;
  justify-content: space-between;
}
.expert-profile .expert-profile-footer .expert-profile-footer-label {
  display: flex;
  align-items: center;
}
.expert-profile .expert-profile-footer .expert-profile-footer-label a {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
.expert-profile .expert-profile-footer .expert-profile-footer-label a mat-icon {
  transform: scale(0.7);
}
.expert-profile .expert-profile-footer .expert-profile-footer-actions {
  display: flex;
  align-items: center;
}
.expert-profile .expert-profile-footer .expert-profile-footer-actions button {
  margin-right: var(--spacing-m);
}
.expert-profile .expert-profile-footer .expert-profile-footer-actions button:last-child {
  margin-right: 0;
}

.availibility-button {
  cursor: pointer;
}

.align-row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.portal-management-container {
  margin: var(--page-margin);
}
.portal-management-container .button {
  margin-bottom: 10px;
}

.opp-topbar {
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  box-shadow: var(--box-shadow-medium);
  position: relative;
  z-index: 1;
}
.opp-topbar .opp-topbar-row {
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  height: 1.875rem;
  justify-content: space-between;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content {
  display: flex;
  align-items: center;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content app-search-select .mat-tree {
  background-color: transparent;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content app-search-select .drop-down {
  width: 250px;
  height: 650px;
  overflow-y: scroll;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .back-arrow {
  color: darkgray;
  margin-right: 0.625rem;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .chevron {
  color: darkgray;
  margin: 0 0.625rem;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .route-text {
  font-size: 0.9375rem;
  color: #706d6d;
  font-weight: 600;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .thin-button {
  margin-right: 1.25rem;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .thin-button.filtered {
  background-color: #ccc;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .master-check-box {
  display: flex;
  align-items: center;
  transform: scale(0.81);
  margin: 0 1.5625rem;
  cursor: pointer;
  color: #bdbdbd;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .master-check-box .checked {
  color: cornflowerblue;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .count {
  margin-right: 1.25rem;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .no-emails-toggle {
  display: flex;
  align-items: center;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .no-emails-toggle mat-icon {
  transform: scale(0.81);
  cursor: pointer;
  color: #bdbdbd;
  margin-right: 0.3125rem;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .no-emails-toggle mat-icon.checked {
  color: cornflowerblue;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .affiliation-toggle {
  display: flex;
  align-items: center;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .affiliation-toggle mat-icon {
  transform: scale(0.81);
  cursor: pointer;
  color: #bdbdbd;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .more-actions {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .more-actions .drop-down {
  right: 0;
  top: 28px;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .search-input {
  display: flex;
  align-items: center;
  height: 0;
  border: 0;
  background-color: transparent;
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .search-input .search-input-icon {
  color: #b4b4b4;
  transform: scale(0.85);
}
.opp-topbar .opp-topbar-row .opp-topbar-row-content .search-input .search-input-input {
  border: 0;
  font-size: 0.875rem;
  outline: none;
  color: #6c757d;
  background-color: transparent;
}

.phase-menu {
  background-color: #fafafa;
  padding: 4px;
  font-size: 10px;
  z-index: 1000;
  box-shadow: var(--box-shadow-drop-down);
  border-radius: 4px;
  padding: 0.5em 0 0.5em 0;
  animation: fadeIn 0.1s ease-out;
  opacity: 1;
  display: block;
}
.phase-menu li {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #333;
  padding: 0.5em 2em 0.5em 0.75em;
  max-width: 18em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phase-menu li:hover {
  background-color: #33a1de;
  color: #fff;
}
.phase-menu li:before {
  content: "";
  float: left;
  margin-right: 0.75em;
  width: 0.5em;
  height: 1em;
  display: inline-block;
}

.expert-list-viewport {
  height: calc(100vh - 150px);
  width: 100%;
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  padding: 0 15px !important;
  width: 100%;
  overflow-x: hidden;
}

.expert-list-loader {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-experts-message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-experts-message i {
  transform: scale(1.8);
  margin-top: 0.625rem;
  color: darkgrey;
}

.expert-underlay {
  width: 100%;
  height: 4.6875rem;
  margin-bottom: var(--spacing-s);
  display: flex;
  position: relative;
  box-shadow: var(--box-shadow-light);
}
.expert-underlay .expert-underlay-actions {
  justify-content: space-between;
  width: 8%;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1.25rem;
  background: linear-gradient(60deg, #5c4d79, #7fa2d8);
}
.expert-underlay .expert-underlay-actions .expert-underlay-action {
  transform: scale(0.877);
  color: white;
  cursor: pointer;
}
.expert-underlay .expert-underlay-actions .expert-underlay-action.no-cursor-events {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
}
.expert-underlay .expert-underlay-actions .expert-underlay-action a {
  color: white;
}
.expert-underlay .expert-overlay {
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #fff;
  transition: 0.3s all;
  display: grid;
  grid-template-columns: 5.25rem 14% 1fr 3.75rem;
  grid-template-rows: 4.6875rem;
  position: absolute;
}
.expert-underlay .expert-overlay.reduce-width {
  left: 8% !important;
}
.expert-underlay .expert-overlay .select-grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.expert-underlay .expert-overlay .select-grid-item .check-box {
  display: flex;
  align-items: center;
  transform: scale(0.81);
  cursor: pointer;
  color: #bdbdbd;
}
.expert-underlay .expert-overlay .select-grid-item .check-box .checked {
  color: cornflowerblue;
}
.expert-underlay .expert-overlay .select-grid-item .check-box .disabled {
  cursor: not-allowed;
  color: #ccc;
}
.expert-underlay .expert-overlay .select-grid-item .open-actions {
  transition: opacity 0.35s;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 10px;
  transform: scale(1.3);
  color: #bdbdbd;
  cursor: pointer;
  user-select: none;
}
.expert-underlay .expert-overlay .select-grid-item .open-actions:hover {
  opacity: 1;
}
.expert-underlay .expert-overlay .select-grid-item .open-actions.rotate-opposite {
  transform: rotate(180deg) scale(1.3) !important;
}
.expert-underlay .expert-overlay .expand-grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.expert-underlay .expert-overlay .expand-grid-item .expand-icon {
  cursor: pointer;
  user-select: none;
  color: #bdbdbd;
}

.identity-grid-item {
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  justify-content: space-evenly;
}
.identity-grid-item .expert-name {
  font-weight: 500;
  color: #404040;
  font-size: 1rem;
}
.identity-grid-item .source-search-code-row {
  display: flex;
  align-items: center;
}
.identity-grid-item .source-search-code-row .source-pill {
  margin-right: 0.3125rem;
}
.identity-grid-item .source-search-code-row .search-id {
  color: #888;
  text-transform: uppercase;
  font-size: 0.7125rem;
}

.details-grid-item {
  padding: 0.625rem;
  display: flex;
}
.details-grid-item .country {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  width: 8.75rem;
}
.details-grid-item .country .country-label {
  position: absolute;
  font-size: 0.65rem;
  font-weight: 400;
  color: #a5a5a5;
  text-transform: uppercase;
  top: 1px;
}
.details-grid-item .country .country-value {
  font-weight: 600;
}
.details-grid-item .email {
  display: flex;
  width: 100%;
  min-width: 15.625rem;
  height: 100%;
  position: relative;
  align-items: center;
  margin-right: 1.5625rem;
}
.details-grid-item .email .email-label {
  position: absolute;
  font-size: 0.65rem;
  font-weight: 400;
  color: #a5a5a5;
  text-transform: uppercase;
  top: 1px;
}
.details-grid-item .email .ellipse-box {
  width: 1.5625rem !important;
  display: flex;
  background-color: #cad0d9;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-size: 1rem;
  height: 1.125rem;
  position: relative;
  cursor: pointer;
  margin-left: 0.3125rem;
  user-select: none;
}
.details-grid-item .email .ellipse-box:hover .drop-down {
  display: block;
}
.details-grid-item .email .ellipse-box .drop-down {
  display: none;
  left: 0;
  top: 20px;
}
.details-grid-item .availability {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
}
.details-grid-item .availability .availability-label {
  position: absolute;
  font-size: 0.65rem;
  font-weight: 400;
  color: #a5a5a5;
  text-transform: uppercase;
  top: 1px;
}
.details-grid-item .availability .availability-list {
  display: flex;
  align-items: center;
}
.details-grid-item .availability .availability-list .availability-pill {
  margin-right: 0.3125rem;
}
.details-grid-item .availability .availability-list .ellipse-box {
  width: 1.5625rem !important;
  display: flex;
  background-color: #cad0d9;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-size: 1rem;
  height: 1.125rem;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.details-grid-item .availability .availability-list .ellipse-box:hover .drop-down {
  display: block;
}
.details-grid-item .availability .availability-list .ellipse-box .drop-down {
  display: none;
  right: 0;
  top: 20px;
}
.details-grid-item .schedule-button {
  align-self: center;
  position: absolute;
  right: 66px;
}
.details-grid-item .conference {
  display: flex;
  align-items: center;
  height: 4.0625rem;
  position: relative;
  top: -5px;
  width: max-content;
  background-color: #f7f8fa;
  font-size: 0.6875rem;
  padding: 0 0.625rem;
  border-radius: 2px;
}
.details-grid-item .conference mat-icon {
  margin-right: 0.3125rem;
  color: var(--muted);
  transform: scale(0.95) translate(-3px, 0);
}
.details-grid-item .conference .meta-info-vertical {
  height: 100%;
}
.details-grid-item .conference .meta-info-vertical .conference-title {
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 15rem;
  overflow: hidden;
  height: 0.8125rem;
  display: initial;
}
.details-grid-item .conference .edit {
  transform: scale(0.78);
  color: var(--muted);
  cursor: pointer;
}
.details-grid-item .affiliation {
  display: flex;
  width: 18.75rem;
  min-width: 18.75rem;
  margin-right: 3.125rem;
  height: 100%;
  position: relative;
  align-items: center;
}
.details-grid-item .affiliation .affiliation-label {
  position: absolute;
  font-size: 0.65rem;
  font-weight: 400;
  color: #a5a5a5;
  text-transform: uppercase;
  top: 1px;
}
.details-grid-item .affiliation .affiliation-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6c757d;
  font-size: 0.8125rem;
}
.details-grid-item .expertise {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
}
.details-grid-item .expertise .expertise-label {
  position: absolute;
  font-size: 0.65rem;
  font-weight: 400;
  color: #a5a5a5;
  text-transform: uppercase;
  top: 1px;
}
.details-grid-item .expertise .expertise-tag-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 9px;
  height: 70%;
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.details-grid-item .expertise .expertise-tag-container::-webkit-scrollbar {
  width: 6px;
}
.details-grid-item .expertise .expertise-tag-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.details-grid-item .expertise .expertise-tag-container::-webkit-scrollbar-thumb {
  background: #dedede;
}
.details-grid-item .expertise .expertise-tag-container::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.ghost-expert-details {
  width: 100%;
  left: 0;
  background-color: #fff;
  transition: 0.3s all;
  display: grid;
  grid-template-columns: 7.5rem 10% 1fr 10.625rem;
  height: 4.6875rem;
  margin-bottom: 0.625rem;
  box-shadow: var(--box-shadow-light);
}
.ghost-expert-details .phase-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ghost-expert-details .phase-div .phase-box {
  font-size: 0.75rem;
  width: 84%;
  display: -webkit-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: slateblue;
  color: white;
  border-radius: 2px;
  font-weight: 400;
}
.ghost-expert-details .go-to-expert-div {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ghost-expert-details .go-to-expert-div button {
  font-weight: 400;
}

.solicitation {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  position: relative;
}
.solicitation .solicitation-header {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.25rem;
  position: relative;
  justify-content: space-between;
}
.solicitation .solicitation-header .solicitation-header-title {
  display: flex;
  align-items: center;
}
.solicitation .solicitation-header .solicitation-header-title h2 {
  margin-right: var(--spacing-s) !important;
}
.solicitation .solicitation-header .solicitation-header-title h2:last-child {
  margin-left: var(--spacing-s) !important;
}
.solicitation .solicitation-recipients {
  display: flex;
  align-items: center;
  /* padding: 0 2rem; */
  border: 1px solid #ccc;
  margin: 0 1.25rem 0.625rem 1.25rem;
  border-radius: 3px;
}
.solicitation .solicitation-recipients .risk-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 6.875rem;
  margin-right: 0.625rem;
  padding: 0.3125rem;
  border-right: 1px solid #ccc;
}
.solicitation .solicitation-recipients .risk-slider .mat-slider-horizontal {
  height: 35px !important;
}
.solicitation .solicitation-recipients app-recipient-list {
  width: 100%;
}
.solicitation .solicitation-recipients app-recipient-list ul.emails {
  max-height: 59px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.solicitation .solicitation-recipients app-recipient-list ul.emails li {
  display: flex;
  flex-wrap: wrap;
}
.solicitation .solicitation-recipients app-recipient-list ul.emails li:hover {
  cursor: default;
}
.solicitation .solicitation-recipients app-recipient-list .chip {
  min-width: 10rem;
  height: 1.25rem;
  padding: 0 0.3125rem 0 1.25rem;
  font-size: 0.8375rem;
  background-color: #f6f6f6;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin: 0 0.3125rem 0.3125rem 0;
}
.solicitation .solicitation-recipients app-recipient-list .unverified-indicator {
  font-weight: 600;
  margin-left: var(--spacing-s);
  margin-right: 0.3125rem;
  transform: scale(1.1);
}
.solicitation .solicitation-recipients app-recipient-list .closebtn {
  transform: scale(0.666);
  cursor: pointer;
}
.solicitation .solicitation-recipients app-recipient-list .closebtn:hover {
  color: var(--main-text-color);
}
.solicitation .subject-input {
  padding: 0 1.25rem;
  flex-grow: 0;
}
.solicitation .solicitation-body {
  display: flex;
  overflow: hidden;
  flex-grow: 1;
}
.solicitation .solicitation-body .hide-menu-div {
  width: 0;
  position: relative;
  transition: all 0.2s ease;
}
.solicitation .solicitation-body .show-menu-div {
  width: 670px;
  position: relative;
  transition: all 0.3s ease;
  animation-fill-mode: forwards;
  animation-name: fade;
  animation-duration: 0.7s;
  border-right: 1px solid #ccc;
}
.solicitation .solicitation-body .keys-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25rem;
}
.solicitation .solicitation-body .keys-menu .keys-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.3125rem;
}
.solicitation .solicitation-body .keys-menu .list {
  flex-grow: 1;
}
.solicitation .solicitation-body .keys-menu .list .list-item {
  flex-direction: column;
}
.solicitation .solicitation-body .keys-menu .list .list-item .required {
  background: red;
}
.solicitation .solicitation-body .template-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25rem;
}
.solicitation .solicitation-body .template-menu .template-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.3125rem;
}
.solicitation .solicitation-body .template-menu .template-tabs {
  display: flex;
  align-items: center;
  margin: 0.3125rem 0;
}
.solicitation .solicitation-body .template-menu .template-tabs .thin-button {
  margin-right: var(--spacing-m);
}
.solicitation .solicitation-body .template-menu .template-tabs .thin-button.active {
  background-color: cornflowerblue;
  color: white;
  border: 0;
}
.solicitation .solicitation-body .template-menu .search-input {
  margin: 0.3125rem 0;
}
.solicitation .solicitation-body .template-menu .template-list {
  list-style-type: none;
  overflow-y: scroll;
  flex-grow: 1;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item {
  display: flex;
  align-items: center;
  min-height: 3.125rem;
  justify-content: space-between;
  border: 1px solid #ccc;
  margin-bottom: var(--spacing-s);
  margin-right: var(--spacing-s);
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item.active-template {
  border: 2px solid cornflowerblue;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item .template-info {
  display: flex;
  flex-direction: column;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item .template-info .template-title {
  font-weight: 600;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item .template-info .template-description {
  font-size: 0.78125rem;
  color: #79848e;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item .action-items {
  margin-left: auto;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item .action-items button {
  cursor: pointer;
  transform: scale(0.86);
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item .action-items button:disabled {
  color: darkgray;
  cursor: default;
}
.solicitation .solicitation-body .template-menu .template-list .template-list-item .action-items button:hover:enabled {
  color: darkslategrey;
}
.solicitation .solicitation-body .template-menu .button {
  margin-top: var(--spacing-s);
}
.solicitation .solicitation-body .template-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.solicitation .solicitation-body .template-form .on-save-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 5px;
  left: 5px;
  bottom: 0px;
  background-color: var(--light);
  box-shadow: 0 0 13px 10px var(--light);
  animation-fill-mode: forwards;
  animation-name: fade;
  animation-duration: 1s;
  animation-direction: reverse;
  z-index: 30;
}
.solicitation .solicitation-body .template-form .on-save-overlay p {
  font-size: 28px;
  color: var(--gray);
}
.solicitation .solicitation-body .template-form .template-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.3125rem;
}
.solicitation .solicitation-body .template-form .template-form-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.solicitation .solicitation-body .template-form .template-form-body .inputs {
  display: flex;
  align-items: center;
}
.solicitation .solicitation-body .template-form .template-form-body .inputs .input-container {
  width: 100%;
  margin-right: var(--spacing-s);
}
.solicitation .solicitation-body .template-form .template-form-body .inputs .input-container:last-child {
  margin-right: 0;
}
.solicitation .solicitation-body .template-form .template-form-body .form-editor {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
.solicitation .solicitation-body .template-form .template-form-body .form-editor pre.ngx-pre {
  font-size: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  white-space: pre-wrap;
}
.solicitation .solicitation-body .template-form .template-form-body .form-editor pre.ngx-pre .form-editor-textarea {
  width: 100%;
  height: 92%;
  padding: 0.625rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  margin: 0;
}
.solicitation .solicitation-body .template-form .template-form-actions {
  display: flex;
  align-items: center;
}
.solicitation .solicitation-body .template-form .template-form-actions .button {
  margin-right: var(--spacing-m);
}
.solicitation .solicitation-body .editor {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
.solicitation .solicitation-body .editor pre.ngx-pre {
  font-size: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  white-space: pre-wrap;
}
.solicitation .solicitation-body .editor pre.ngx-pre .editor-textarea {
  width: 100%;
  height: 92%;
  padding: 0.625rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 var(--spacing-m);
  outline: none;
}
.solicitation .solicitation-body .editor .template-form-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  z-index: 5;
  top: 100%;
  opacity: 0;
  transition: top 0.5s ease 0s;
  animation-fill-mode: forwards;
  animation-name: fade;
  animation-duration: 3s;
}
.solicitation .solicitation-body .editor .template-form-container app-new-template {
  display: block;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.1s;
}
.solicitation .solicitation-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.75rem;
  padding: 0 1.25rem;
  border-top: 1px solid #ccc;
}
.solicitation .solicitation-footer .button-group {
  display: flex;
  align-items: center;
}
.solicitation .solicitation-footer .button-group .error-text {
  margin-right: 5px;
}
.solicitation .solicitation-footer .button-group mat-icon {
  margin-right: 5px;
}
.solicitation .solicitation-footer .button-group .button {
  margin-right: var(--spacing-s);
}
.solicitation .solicitation-footer .button-group .button:last-child {
  margin-right: 0;
}
.solicitation .solicitation-footer .button-group .highlight {
  border: 1px solid red;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.preview {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.preview section .message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-image: var(--alt-gradient);
  padding: 10px;
}
.preview section .message span {
  width: 100%;
  display: flex;
  align-items: center;
}
.preview section .message span h5 {
  color: var(--white);
  margin-left: 10px;
  font-size: 1rem;
  font-weight: 400;
}
.preview section .message mat-icon {
  width: 25px;
  text-align: right;
  color: var(--white);
  cursor: pointer;
}
.preview section .inner-preview-container {
  display: flex;
  width: 100%;
  padding: 0;
}
.preview section .inner-preview-container .left {
  width: 100px;
  background-color: var(--main-bg-colour);
  color: var(--main-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--border-gray);
}
.preview section .inner-preview-container .left.topBorder {
  border-top: 1px solid var(--grey);
}
.preview section .inner-preview-container .right {
  flex: 1;
  color: var(--main-text-color);
  overflow-y: scroll;
  min-height: 47px;
  max-height: 150px;
  padding: 5px;
}
.preview section .inner-preview-container .right.topBorder {
  border-top: 1px solid var(--grey);
  display: flex;
  align-items: center;
}
.preview .preview-body {
  flex-grow: 1;
}
.preview .preview-body article {
  border-top: 1px solid var(--grey);
  padding: 10px;
  z-index: 1;
  overflow: auto;
}
.preview .preview-body article p.MsoNormal {
  margin: 0;
}
.preview footer {
  padding: 10px 20px;
  border-top: 1px solid var(--grey);
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 3.75rem;
}
.preview footer button {
  margin-right: 20px;
}
.preview footer button:last-child {
  margin-right: 0;
}

dialog {
  width: 480px;
  height: 180px;
  border: 0;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.2);
  z-index: 4;
}
dialog .dialog-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
dialog .dialog-content-container .dialog-header {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
}
dialog .dialog-content-container .dialog-header h3 {
  font-weight: 400;
}
dialog .dialog-content-container .dialog-header h5 {
  font-size: 14px;
  margin-bottom: 8px;
}
dialog .dialog-content-container .dialog-body {
  display: -webkit-box;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-evenly;
}

.tab-title {
  margin-top: 15px;
}

.opportunity {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.opportunity mat-tab-group {
  height: 100%;
}
.opportunity mat-tab-group .dialog-tab {
  padding: 20px 10px;
  overflow: hidden;
  height: 100%;
}
.opportunity mat-tab-group .dialog-tab .horizontal-list {
  margin-top: 10px;
  margin-bottom: 10px;
}
.opportunity mat-tab-group .dialog-tab .button {
  float: right;
}
.opportunity mat-tab-group .dialog-tab .inputs-container {
  display: flex;
  flex-direction: row;
}
.opportunity mat-tab-group .dialog-tab .inputs-container .container {
  padding-right: 10px;
  width: 50%;
}
.opportunity mat-tab-group .dialog-tab .inputs-container .container:last-child {
  padding-left: 10px;
}
.opportunity mat-tab-group .dialog-tab .inputs-container .input-container {
  margin-bottom: 10px;
}
.opportunity mat-tab-group .dialog-tab .inputs-container .horizontal-list .horizontal-list-item {
  margin-bottom: 5px;
}
.opportunity .call-length-container {
  margin-top: 10px;
  display: flex;
}
.opportunity .call-length-container .input-container:not(:last-child) {
  margin-right: 10px;
}
.opportunity .honorarium-container {
  display: flex;
  margin: 10px 0;
}
.opportunity .honorarium-container .input-container {
  margin-right: 10px;
}
.opportunity .horizontal-list {
  margin-bottom: 10px;
}
.opportunity .horizontal-list .horizontal-list-item {
  background: white;
  padding: 8px 13px;
  margin-bottom: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.opportunity .horizontal-list .horizontal-list-item .nav-link {
  display: flex;
  align-items: center;
}
.opportunity .horizontal-list .horizontal-list-item .nav-link .nav-link-name {
  font-size: 14px;
  font-weight: 800;
  color: rgb(108, 117, 125);
}
.opportunity .horizontal-list .horizontal-list-item .nav-link .nav-link-icon {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}
.opportunity .horizontal-list .close-dialog {
  margin-left: auto;
  cursor: pointer;
}
.opportunity .content-body-grid {
  display: flex;
  padding: 20px;
  height: 630px;
  width: 100%;
}
.opportunity .content-body-grid .left-column {
  display: flex;
  flex-direction: column;
}
.opportunity .content-body-grid .left-column .stats {
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
}
.opportunity .content-body-grid .left-column .stats .inner-metrics {
  display: flex;
}
.opportunity .content-body-grid .left-column .stats .inner-metrics .width-wrapper {
  width: 25%;
  margin-bottom: auto;
  margin-top: auto;
}
.opportunity .content-body-grid .left-column .stats .inner-metrics .vr {
  height: 45%;
  width: 1px;
  background-color: #f1f1f1;
}
.opportunity .content-body-grid .left-column .searches {
  display: flex;
  margin-top: 10px;
  height: 56%;
  flex-direction: column;
}
.opportunity .content-body-grid .right-column {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.opportunity .content-body-grid .right-column .section {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  min-height: 20%;
}
.opportunity .content-body-grid .right-column .section .inputs-container {
  display: flex;
  flex-direction: column;
}
.opportunity .content-body-grid .right-column .section .horizontal {
  margin-top: 10px;
  display: flex;
}
.opportunity .content-body-grid .right-column .hr {
  height: 100%;
  background-color: #f1f1f1;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
}

.search-list {
  overflow-y: scroll;
  max-height: 300px;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.search-list::-webkit-scrollbar {
  width: 10px;
}
.search-list::-webkit-scrollbar-track {
  background: transparent;
}
.search-list::-webkit-scrollbar-thumb {
  background: #888;
}
.search-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.search-list .search-tile {
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(222, 222, 222, 0.8705882353);
}
.search-list .search-tile .left-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.search-list .search-tile .left-content .search-title {
  font-weight: 400;
  font-size: 14px;
  width: 97%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-list .search-tile .left-content .terms {
  color: var(--muted);
  width: 97%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
}
.search-list .search-tile .left-content .status {
  font-size: 12px;
  text-transform: uppercase;
  color: #bdbdbd;
}
.search-list .search-tile .remove-icon {
  transform: scale(0.85);
  color: #ccc;
  cursor: pointer;
}

.opportunity-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
}
.opportunity-content h2 {
  margin-bottom: var(--spacing-m);
}

.button-div {
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-top: 1px solid #f1f1f1;
}

app-geographic-progress .progress-container {
  display: flex;
  flex-direction: column;
}
app-geographic-progress .progress-container table {
  width: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
app-geographic-progress .progress-container table th {
  width: 100px;
  padding-left: 10px !important;
}
app-geographic-progress .progress-container table th:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
app-geographic-progress .progress-container table th:nth-child(n+3) {
  text-align: right;
  padding-right: 10px !important;
}
app-geographic-progress .progress-container table th:nth-child(n+3) mat-cell {
  border: none;
}
app-geographic-progress .progress-container table td:nth-child(n+3) {
  text-align: right;
  padding-right: 10px;
}
app-geographic-progress .progress-container table td:nth-child(n+3) mat-cell {
  border: none;
}
app-geographic-progress .progress-container table td {
  padding-left: 10px !important;
}
app-geographic-progress .progress-container table td:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
app-geographic-progress .progress-container table .header-body {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
app-geographic-progress .progress-container table .header-body:nth-child(even) {
  background: rgb(248, 248, 248);
}
app-geographic-progress .progress-container table .header-body:nth-child(odd) {
  background: #fff;
}
app-geographic-progress .progress-container table .header-row {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgb(248, 248, 248) !important;
}
app-geographic-progress .progress-container button {
  margin: var(--spacing-m) 0;
}

.engagements-overview-container {
  padding: 20px;
  height: 680px;
}
.engagements-overview-container h2 {
  margin-bottom: 20px;
}
.engagements-overview-container .engagement-list-container {
  padding-bottom: 50px;
}

app-conference-schedule-form app-timezone-selector .input-container {
  margin-bottom: 2rem;
  position: relative;
}
app-conference-schedule-form app-timezone-selector .input-container .base-input-input {
  width: 35rem;
}
app-conference-schedule-form app-timezone-selector .input-container .selected-tz-input {
  width: 35rem;
}
app-conference-schedule-form app-timezone-selector .input-container .drop-down {
  position: absolute;
  top: 54px;
  max-height: 29rem;
  overflow-y: scroll;
}
app-conference-schedule-form app-timezone-selector .input-container .horizontal-list {
  margin-top: 1rem;
}

app-conference-rescheduler app-timezone-selector .input-container {
  margin-bottom: 2rem;
  position: relative;
}
app-conference-rescheduler app-timezone-selector .input-container .base-input-input {
  width: 35rem;
}
app-conference-rescheduler app-timezone-selector .input-container .selected-tz-input {
  width: 35rem;
}
app-conference-rescheduler app-timezone-selector .input-container .drop-down {
  position: absolute;
  top: 54px;
  max-height: 29rem;
  overflow-y: scroll;
}
app-conference-rescheduler app-timezone-selector .input-container .horizontal-list {
  margin-top: 1rem;
}

app-expert-responded-detail h3 {
  margin-bottom: 4px;
}
app-expert-responded-detail .detail-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

app-responded-dialog {
  height: 100%;
}

.checkbox-bar {
  width: 48%;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-s);
  border: 1px solid #cdcdcd;
  cursor: pointer;
  background-color: #f9f9f9;
}
.checkbox-bar.checkbox-bar-active {
  background-color: cornflowerblue;
  border: 0;
}
.checkbox-bar.checkbox-bar-active p {
  color: white !important;
}

.query-scroll-container {
  overflow-y: auto;
  height: 100%;
  margin-right: -24px;
  padding-right: 24px;
}

.add-condition-button {
  min-width: 60px;
  height: 40px;
  background-color: mediumseagreen;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 15px;
  cursor: pointer;
}
.add-condition-button mat-icon {
  transform: scale(1.1);
}

.search-filter-form {
  height: 100%;
}
.search-filter-form .search-filter-header {
  width: 100%;
  padding: 4px 8px;
}
.search-filter-form .mat-checkbox {
  width: 100%;
  height: 24px;
}
.search-filter-form .mat-checkbox .mat-checkbox-layout {
  width: 100%;
}
.search-filter-form .mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.search-filter-form .mat-checkbox .mat-checkbox-layout .mat-checkbox-label .search-filter-name {
  overflow: hidden;
  max-width: 95%;
}
.search-filter-form .mat-checkbox .mat-checkbox-layout .mat-checkbox-label .search-filter-count {
  color: #aaa;
}

.scheduler-container {
  display: flex;
  flex-direction: column;
}
.scheduler-container .scheduler-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 0 var(--spacing-m);
}
.scheduler-container .scheduler-tabs {
  flex-grow: 1;
}

.schedule-form {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-m);
  box-shadow: 0 2px 5px 0 rgba(77, 95, 128, 0.15);
  border: 1px solid var(--border-gray);
  height: 100%;
  width: 100%;
  background-color: #fff;
  margin: 0;
}
.schedule-form h3 {
  margin-bottom: var(--spacing-l);
  font-size: 1.2rem;
}
.schedule-form .schedule-form-inputs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.schedule-form .schedule-form-inputs h4 {
  font-size: 16px;
  font-weight: 600;
  color: #586673;
  margin-bottom: var(--spacing-s);
}
.schedule-form .schedule-form-inputs .form-input-row {
  display: flex;
  margin-bottom: var(--spacing-l);
}
.schedule-form .schedule-form-inputs .form-input-row .datetime-span {
  display: flex;
  align-items: center;
}
.schedule-form .schedule-form-inputs .form-input-row .datetime-span .base-input-input {
  width: 100%;
}
.schedule-form .schedule-form-inputs .form-input-row .datetime-span mat-icon {
  transform: scale(0.85);
  margin-left: 10px;
  cursor: pointer;
}
.schedule-form .schedule-form-inputs .form-input-row .input-container {
  width: 30%;
  margin-right: 20px;
  position: relative;
}
.schedule-form .schedule-form-inputs .form-input-row .input-container .label {
  position: relative;
}
.schedule-form .schedule-form-inputs .form-input-row .input-container .label mat-icon {
  transform: scale(0.56);
  position: absolute;
  color: #303030;
  cursor: help;
  top: -8px;
}
.schedule-form .schedule-form-inputs .form-input-row .input-container .timezone-selector-input-container .drop-down {
  height: 24rem;
  overflow-y: scroll;
}
.schedule-form .schedule-form-inputs .form-input-row .input-container mat-hint {
  position: absolute;
  top: 43px;
}
.schedule-form .toggle-section {
  display: flex;
  gap: 10px;
}
.schedule-form .toggle-section .recording-toggle-box {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #f2f2f2;
  padding: 0 var(--spacing-m);
  border: 1px solid #ccc;
  margin-bottom: var(--spacing-l);
  width: 50%;
}
.schedule-form .toggle-section .recording-toggle-box mat-icon {
  transform: scale(1.3);
}
.schedule-form .toggle-section .recording-toggle-box mat-icon.payment-active-icon {
  color: cornflowerblue;
}
.schedule-form .toggle-section .recording-toggle-box mat-icon.payment-inactive-icon {
  color: lightgray;
}
.schedule-form .toggle-section .recording-toggle-box p {
  margin: 0 20px;
}
.schedule-form .toggle-section .recording-toggle-box .toggle-container {
  display: flex;
  align-items: center;
}
.schedule-form .toggle-section .recording-toggle-box .toggle-container p {
  margin: 0 10px;
}
.schedule-form .schedule-form-actions {
  display: flex;
  align-items: center;
  height: 2.3rem;
  justify-content: flex-end;
}
.schedule-form .schedule-form-actions button {
  margin-right: var(--spacing-m);
}
.schedule-form .schedule-form-actions button:last-child {
  margin-right: 0;
}
.schedule-form .schedule-form-list div {
  display: flex;
  align-items: center;
}
.schedule-form .schedule-form-list div span:first-child {
  flex: 0 1 120px;
}
.schedule-form .schedule-form-list div select {
  width: 260px;
}

.validation-warning {
  color: indianred !important;
  font-size: 0.7555rem;
  margin-top: 4px;
  margin-bottom: 6px;
}

.validation-info {
  color: var(--light-blue) !important;
  font-size: 0.7555rem;
  margin-top: 4px;
  margin-bottom: 6px;
}

.tabs-header {
  display: flex;
  align-items: center;
}
.tabs-header button {
  height: 30px;
  min-width: 70px;
  border: 0;
}
.tabs-header button.active-btn {
  background-color: #dfe3ed;
}
.tabs-header .warning-span {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
}
.tabs-header .warning-span mat-icon {
  margin-right: 10px;
}