.selected-tz-input {
  height: 2rem;
  padding: 0 0.5rem;
  background-color: rgba(220, 229, 250, 0.1);
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
}
