.drop-down {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fcfcfc;
  padding: 0.625rem;
  box-shadow: var(--box-shadow-drop-down);
  list-style-type: none;
  width: max-content;
  z-index: 100;
  border-radius: 5px;
  .drop-down-item {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-s);
    color: #6c757d;
    width: 100%;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .interactable {
      margin-left: auto;
      &:hover {
        cursor: pointer;
        color: lightslategrey;
      }
    }
    .drop-down-item-icon {
      margin-right: var(--spacing-s);
    }
  }
}
