@import 'styles/variables';
@import 'styles/core';

.mat-tab-label {
  height: 3rem !important;
}

.mat-horizontal-stepper-header-container {
  height: 4rem !important;
}

.mat-step-header {
  height: 4rem !important;
}

#schedule-client-editor {
  height: 411px !important;
}

.clickable:hover {
  cursor: pointer;
  background-color: var(--light-grey);
}

:root {
  --amplify-primary-color: var(--primary);
}

a.fc-daygrid-event.other-calendar-event {
  color: #d52762;
}
