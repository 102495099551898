.card {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 1px 9px rgb(144 140 147 / 5%), 0 1px 10px rgb(34 33 35 / 10%);
  overflow: hidden;
  height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
  &__header {
    padding: 10px 20px;
  }
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    &--overflow {
      overflow-y: scroll;
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__actions {
    padding: 20px;
  }
  &--width100 {
    width: 100%;
  }
  &--marginRightLg {
    margin-right: var(--spacing-l);
  }
}

mat-card.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: 0 1px 9px rgb(144 140 147 / 5%), 0 1px 10px rgb(34 33 35 / 10%);
  border-radius: 10px;
  padding: 24px;
}
