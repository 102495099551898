.connect-grid {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 16rem 1fr;
  grid-template-rows: 50px 1fr;
  &__sidebar {
    grid-row: 1/3;
    grid-column: 1/2;
  }
  &__body {
    grid-row: 2/3;
    grid-column: 2/3;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  &__topbar {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}
