mat-panel-title {
  width: 100%;
  .engagement-title {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    span {
      font-size: 18px;
      color: royalblue;
      margin-right: 10px;
    }
    .engagement-title-payment-status {
      display: flex;
      align-items: center;
      flex-grow: 1;
      mat-icon {
        color: seagreen;
        margin-right: var(--spacing-s);
      }
    }
    p {
      font-weight: 800;
    }
  }
}
.billing-form {
  &.completed-payment-opacity {
    opacity: 0.4;
    pointer-events: none;
  }
  .payment-status {
    pointer-events: none;
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-m);
    p {
      margin-right: var(--spacing-s);
    }
    mat-radio-button {
      margin-right: var(--spacing-s);
    }
  }
  .input-row {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-m);
    .input-container {
      margin-right: var(--spacing-s);
      width: 30%;
      &.width-100 {
        width: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .payment-active-toggle-box {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #f2f2f2;
    padding: 0 var(--spacing-m);
    border: 1px solid #ccc;
    margin-bottom: var(--spacing-l);
    mat-icon {
      transform: scale(1.3);
      &.payment-active-icon {
        color: cornflowerblue;
      }
      &.payment-inactive-icon {
        color: lightgray;
      }
    }
    p {
      margin: 0 20px;
    }
    .toggle-container {
      display: flex;
      align-items: center;
      p {
        margin: 0 10px;
      }
    }
  }
  .engagement-action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      flex-grow: 1;
    }
    button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
