:root {
  //--main-text-color: #212121;
  --main-text-color: #3f3f3f;
  --main-bg-colour: #f2f4f7;
  --main-colour: #2b76f0;
  --label-color: #8a92a5;
  --light-blue: #4f90d1;
  --dark-grey: #333333;
  --grey: #e3e7ef;
  --dark-gray: #888;
  --light-grey: #dbdbdb;
  --bg-gray: #f5f5f5;
  --border-gray: #ddd;
  --white: #ffffff;
  --white-upload: #f7f8fa;
  --warning-red: #d13026;
  --warning-red-highlight: #ee372a;
  --traffic-yellow: #ffc20a;
  --traffic-orange: #e66100;
  --traffic-green: #40b0a6;
  --chip-bg: #f1f1f1;
  --form-font-color: #495057;
  --button-green: #1fab4d;
  --very-light-gray: #fbfbfb;
  --powderblue: powderblue;
  --royalblue: royalblue;

  --light-blue-font: #dce5fa;

  --placeholder-image-font-size: 8em;
  --label-font-size: 0.6em;
  --cross-font-size: 0.5em;

  --delivered-gradient: linear-gradient(0.25turn, transparent, 80%, #40b0a6);
  --pending-gradient: linear-gradient(0.25turn, transparent, 80%, #f2784b);
  --warning-gradient: linear-gradient(0.25turn, transparent, 80%, #cb2d3e);

  --source-trad: #52be80;
  --source-deep: #ff5733;
  --source-net: #c62828;
  --source-strip: #481542;
  --source-link: #00838f;
  --source-man: #777777;
  --source-rec: #ff1493;

  --techspert-gradient: linear-gradient(135deg, #667eea 0%, #22416f 100%);
  --alt-gradient: linear-gradient(135deg, #202742 0%, #21364d 100%); //my fav
  //--alt-gradient: linear-gradient(135deg, #136194 0%,  #585b97 100%); //my fav

  //--main-gradient: linear-gradient(135deg, #22416f 0%, #5f62ac 100%); //side bar gradient

  --main-gradient: linear-gradient(
    135deg,
    #22416f 0%,
    #585b97 100%
  ); //side bar gradient

  //5.0.0 Variables;

  --muted: #6c757d;
  --primary: #9c27b0;
  --info: #00bcd4;
  --success: #4caf50;
  --warning: #ff9800;
  --danger: #f44336;

  // BGcolors for alerts
  --alert-danger-color: #f55145;
  --alert-info-color: #00cae3;
  --alert-success-color: #55b559;
  --alert-warning-color: #ff9e0f;
  --alert-primary-color: #a72abd;
}
