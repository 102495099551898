:root {
  --page-margin: 0 1.4rem 1.4rem 1.4rem;

  --box-shadow-dark: 0 2px 6px rgba(0, 0, 0, 0.2);
  --box-shadow-medium: 0 1px 4px rgba(0, 0, 0, 0.14),
    0 1px 4px rgba(0, 0, 0, 0.01);
  --box-shadow-light: 0 1px 3px rgba(0, 0, 0, 0.04),
    0 1px 2px rgba(0, 0, 0, 0.01);
  --box-shadow-drop-down: 0 0 12px rgba(0, 0, 0, 0.25);

  --app-background-color: #f2f4f7;

  --font-size-h1: 1.3rem;
  --font-size-h2: 1.03rem;
  --font-size-h3: 1.03rem;
  --font-size-p: 0.875rem;
  --font-size-pill: 0.75rem;

  --font-weight-h1: 600;
  --font-weight-h2: 700;
  --font-weight-h3: 600;
  --font-weight-p: 400;

  --font-color: #6c757d;

  --email-fail-color: #dc3545;
  --email-success-color: #20c997;
  --email-pending-color: #ffa500;
  --email-default-color: #4f90d1;

  --primary-button-color: #1e87f0;
  --primary-button-hover-color: #0f7ae5;
  --primary-button-font-color: #fff;

  --secondary-button-color: #fff;
  --secondary-button-font-color: black;
  --secondary-button-border: 1px solid #e5e5e5;
  --secondary-button-hover-border: 1px solid #ccc;

  --danger-button-color: indianred;
  --danger-button-hover-color: #ee395b;
  --danger-button-font-color: #fff;

  --spacing-xl: 2.5rem; //40px equiv
  --spacing-l: 1.875rem; //30px equiv
  --spacing-m: 1.25rem; //20px equiv
  --spacing-s: 0.625rem; //10px equiv
  --spacing-xs: 0.3125rem; //5px equiv
}
