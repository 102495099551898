.list {
  list-style-type: none;
  padding: 0;
  &__item {
    display: flex;
    border-bottom: 1px solid #dededede;
    padding: 10px 0;
    align-items: center;
    &--clickable {
      cursor: pointer;
    }
  }
}
