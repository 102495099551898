.card-statistics-navigation {
  font-size: var(--font-size-p);
  font-weight: 400;
  color: rgb(195, 174, 173);
  border-top: 1px solid rgba(195, 174, 173, 0.3);
  cursor: pointer;

  .material-icons {
    font-size: 14px;
    margin-right: 3px;
  }
}
