.navMenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__img {
    width: 165px;
    margin-bottom: 10px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
  }
  &__link {
    display: flex;
    height: 30px;
    font-weight: 800;
    font-size: 14px;
    align-items: center;
    color: rgba(55, 53, 47, 0.6);
    margin-bottom: 10px;
    padding: 0 20px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    mat-icon {
      transform: scale(0.8) rotate(0.3deg);
      margin-right: 10px;
    }
    &:hover {
      background-color: #f1f1f1;
    }
    &.active {
      color: #4e4e4e;
    }
  }

  &__footer {
    width: 100%;
    padding: 20px;

    button {
      width: 100%;
    }
  }
}

mat-sidenav {
  background-color: #fdfdfd;
  width: 180px;
}

mat-sidenav-container {
  height: 100vh;
}
