.statistics-container {
  display: flex;
  flex-direction: column;
  margin: var(--page-margin);
  .search-input {
    width: 18.75rem;
  }
  .selection-list {
    display: flex;
    flex-direction: row;
    min-height: 1.875rem;
    flex-wrap: wrap;
    margin: 0.625rem 0 0.3125rem 0;
    li {
      height: 1.5625rem;
      padding: 0 0.3125rem 0 var(--spacing-m);
      list-style-type: none;
      display: flex;
      align-items: center;
      margin: 0 var(--spacing-m) 0.3125rem 0;
      background-color: #fff;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
      font-size: var(--font-size-p);
      mat-icon {
        transform: scale(0.7);
        cursor: pointer;
      }
    }
  }
}

//todo fit this in as its duplicate
.percentage-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .stat-span {
    font-weight: 400;
    font-size: 1.34375rem;
  }
  .label {
    font-size: var(--font-size-p);
    font-weight: 400;
  }
}
