.table-opportunity-data-view {
  width: 100%;

  .mat-header-row {
    .mat-column-options {
      width: 10%;
      text-align: right;
    }
  }

  .mat-row {
    &.is-editing {
      background-color: #ccc;
      font-weight: bold;
    }

    .mat-column-options {
      text-align: right;

      .action-button {
        cursor: pointer;
        margin: 0;
        padding: 0;
        min-width: 25px;
      }
    }
  }
}

.table-expert-targets {
  .mat-header-row {
    .mat-column-segment {
      width: 15%;
    }

    .mat-column-amount {
      width: 5%;
    }

    .mat-column-unitsPerEngagement {
      width: 5%;
    }

    .mat-column-owner {
      width: 15%;
    }

    .mat-column-emailSubject {
      width: 20%;
    }

    .mat-column-screenerLink {
      width: 40%;
    }
  }
}

.table-currency-honorarium {
  .mat-header-row {
    .mat-column-currency {
      width: 30%;
    }

    .mat-column-starting {
      width: 30%;
    }

    .mat-column-max {
      width: 30%;
    }
  }
}

.form-opportunity-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  input::placeholder {
    color: rgba(228, 220, 220, 0.7) !important;
  }

  .form-opportunity-inline {
    display: flex;
    align-items: flex-end;

    .input-container {
      margin-right: 10px;
    }
    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
