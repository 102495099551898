.ct-tableEmptyState {
  background-color: #eee;
  border: 1px solid #dedede;
  border-top: 0;
  padding: 24px;

  .ct-tableEmptyStateHeader {
    font-size: 24px;
  }
}

.ct-noPaddingTable.mat-table {
  width: 100%;
  .mat-header-row {
    .mat-header-cell {
      padding: 0;
    }
    .mat-column-options {
      text-align: right;
    }
  }
  .mat-row {
    .mat-cell {
      padding: 0 16px 0 0;
    }
    .mat-cell:last-child {
      padding: 0;
    }
    .mat-column-options {
      text-align: right;
    }
    &:hover {
      background: whitesmoke;
    }
  }
}
