.block-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow-dark);
  padding: 0 1.25rem 1.25rem 1.25rem;
  height: 100%;
  .block-card-header {
    min-height: 3.25rem;
    display: flex;
    align-items: center;
  }
  .search-input {
    width: 13rem;
  }
  .input-container {
    width: 18.75rem;
  }
  .card-body {
    height: 100%;
  }
  .block-card-footer {
    min-height: 3.75rem;
    display: flex;
    align-items: flex-end;
  }
}
