.flexColumn {
  display: flex;
  flex-direction: column;
  &--marginRightMd {
    margin-right: var(--spacing-m);
  }
  &--marginLeftMd {
    margin-left: var(--spacing-m);
  }
  &--width25 {
    width: 25%;
  }
  &--width50 {
    width: 50%;
  }
  &--width100 {
    width: 100%;
  }
}
