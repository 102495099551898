.thin-button {
  padding: 0 0.75rem;
  min-width: 95px;
  height: 1.25rem;
  font-size: 0.75rem;
  border: 1px solid darkgrey;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    border: 1px solid #ccc;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .thin-button-icon {
    transform: scale(0.6);
  }
  app-shared-btn-loader {
    transform: scale(0.4);
  }
}
