.ct-chipList {
  .mat-chip {
    height: 20px;
    min-height: 0;
    &.fail {
      color: white;
      background-color: var(--email-fail-color);
    }
    &.success {
      color: white;
      background-color: var(--email-success-color);
    }

    &.pending {
      color: white;
      background-color: var(--email-pending-color);
    }
    &.default {
      color: white;
      background-color: var(--email-default-color);
    }
  }
}
