.topbar {
  display: flex;
  align-items: center;
  &__menuIcons {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
    color: rgba(55, 53, 47, 0.8);

    &:hover {
      background-color: #f1efef;
    }
  }

  .bell {
    margin-left: auto;
    cursor: pointer;
    background-color: #0071ec;
    color: white;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
  }
}

mat-toolbar {
  background-color: #fff !important;
  height: 48px !important;
  min-height: 48px;
  box-shadow: 0 1px 10px rgb(0 0 0 / 8%), 0 1px 10px rgb(0 0 0 / 1%);
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
