.email-pill {
  background-color: #f2f2f2;
  border-radius: 20px;
  color: #404040;
  min-width: 10rem;
  width: 13.75rem;
  display: -webkit-box;
  display: flex;
  align-items: center;
  height: 1.25rem;
  padding: 0 0.625rem;
  cursor: copy;

  &.email-pill-selected {
    border: 2px solid cornflowerblue;
  }

  .email-pill-text {
    font-size: var(--font-size-pill);
    width: 100%;
    padding: 0 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .email-status-circle {
    cursor: default;
    margin-left: 0.3125rem;
  }

  &.highlight-danger {
    background-color: #d13026;
    color: #fff;
  }
}
